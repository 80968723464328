<template>
	<div class="home">
		<el-row :gutter="16" >
			<el-col :span="6">
				<div class="start" @click="handleStart">
					<div class="bg"></div>
					<div class="desc">
						<div class="desc-t">开始创作</div>
						<p>轻松实现创意图片</p>
					</div>
				</div>
			</el-col>
			<el-col :span="9">
				<div class="history">
					<div class="title">
						<span class="text">创作记录</span>
						<span class="more" @click="$router.push({name: 'myfile'})">更多 <i class="icon-lujing iconfont"></i> </span>
					</div>
					<div class="history-list" v-if="fileList && fileList.length > 0">
						<div class="history-item" v-for="item in fileList" :key="item.href" @click="">
							<img v-if="getMediaType(item.href) === 'image'" :src="addSmallSuffix(item.href)" alt="">
							<xg-player v-if="getMediaType(item.href) === 'video'" :src="item.href" :options="{height: '100%'}" />
						</div>
					</div>
					<div v-else-if="isLogin" style="height:50px;display: flex;align-items: center;">
						您还没有创作记录，点击 <span style="cursor: pointer;color: #409eff;" @click="handleToCreate">去创作</span>
					</div>
					<div v-else style="height:50px;display: flex;align-items: center;">
						您还没有登录，点击 <span style="cursor: pointer;color: #409eff;" @click="$router.push('/login')">去登录</span>
					</div>
				</div>
			</el-col>
			<el-col :span="9">
				<div class="advertisement">
					<div class="adver-img">
						<img src="../assets/image/advart.png" alt="">
					</div>
				</div>
			</el-col>
		</el-row>
		<div class="tools">
			<div class="tools-text">热门工具</div>
			<div class="more">更多 <i class="icon-lujing iconfont"></i></div>
		</div>
<!--		<el-row :gutter="16" type="flex" style="flex-wrap: nowrap;">-->

<!--			<el-col-->
<!--				v-for="(item, ind) in toolList"-->
<!--				:span="ind === 0 ? 6 : 3"-->

<!--			>-->
		<div class="tool-list">
				<div
					v-for="(item, ind) in toolList"
					:class="{'tool-item': true}" @click="toCreate(item)">
					<div class="tool-item-header">
						<div class="title ellipsis">{{ item.name }}</div>
						<div class="desc">{{ item.remark }}</div>
					</div>
					<div class="img">
						<img :src="(item.avatar)" alt="">
					</div>
				</div>
		</div>
<!--			</el-col>-->

<!--		</el-row>-->

		<div class="tools">
			<div class="tools-text">创作广场</div>
			<div class="">
<!--				<el-dropdown @command="filterCommandChange">-->
<!--					<span class="el-dropdown-link">-->
<!--						{{ filterText }}<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--					</span>-->
<!--					<el-dropdown-menu slot="dropdown">-->
<!--						<el-dropdown-item-->
<!--							v-for="item in filterList"-->
<!--							:key="item.value"-->
<!--							:command="item.value">{{ item.text }}</el-dropdown-item>-->
<!--					</el-dropdown-menu>-->
<!--				</el-dropdown>-->
			</div>
		</div>

		<div class="style-list">
			<div
				v-for="item in styleList"
				:class="{'style-item': true, 'checked': item.id === styleChecked}"
				@click="setStyleChecked(item)"
			>
				{{ item.category }}
			</div>
		</div>

		<div style="min-height: 300px;">
<!--			<Waterfall ref="waterfallRef" />-->
			<waterfall3  />
		</div>
<!--		<transition name="fade" mode="out-in">-->
<!--			<Waterfall2  ref="waterfallRef" />-->
<!--		</transition>-->


	</div>
</template>

<script>
import Waterfall from "@/components/Waterfall";
import Waterfall2 from "@/components/waterfall2";
import waterfall3 from "@/components/waterfall3";
import storage from "@/util/storage";
import {getFileCategory} from "@/util/request";
import {mapGetters} from "vuex";
import {addSmallSuffix} from "@/util";
import vm from "@/util/eventUtil";
import {getMediaType} from "@/util";

export default {
	name: "Home",
	components: {
		Waterfall2, Waterfall, waterfall3
	},
	mounted() {
		this.toolList = storage.get('gradioapps')
		this.getAppList()
		this.getFileList()
		getFileCategory().then(res => {
			if (res.code === 200) {
				this.styleList = res.data.data
			}
		})
		window.addEventListener('resize', this.handleResize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
	},
	data() {
		return {
			toolList: [
				// {title: '图生图', desc: '图片卡通化', img: '', id: '0'},
				// {title: '文生图', desc: '输入提示文字，选择风格，生成图片', img: '', id: '1'},
				// {title: '文生图', desc: '输入提示文字，生成图片', img: '', id: '2'},
				// {title: '动画视频', desc: '输入图片和视频，生成动画视频', img: '', id: '3'},
				// {title: '图生图', desc: '图片卡通化', img: '', id: '4'},
				// {title: '文生图', desc: '输入提示文字，选择风格，生成图片', img: '', id: '6'},
				// {title: '文生图', desc: '输入提示文字，生成图片', img: '', id: '7'},
				// {title: '动画视频', desc: '输入图片和视频，生成动画视频', img: '', id: '5'},
			],
			filterList: [
				{value: 'hot', text: '最热'},
				{value: 'new', text: '最新'},
			],
			filterCommand: 'hot',
			styleList: [
				{category: '风景', descr: '', id: 1}
			],
			styleChecked: '粘土风',
			pictureList: [
			],
			fileList: []
		}
	},
	computed: {
		...mapGetters(['isLogin']),
		filterText () {
			return this.filterList.find(i => i.value === this.filterCommand)?.text
		}
	},
	methods: {
		getMediaType,
		addSmallSuffix,
		handleResize () {
		},
		handleStart () {
			const {fileList = [], toolList = []} = this
			if (fileList.length > 0) {
				// window.open('/aicreate?id=' + fileList[0].source)
				let url = this.$router.resolve({
					name: 'aicreate',
					query: {
						id: fileList[0].source
					}
				})
				window.open(url.href, '_blank')
			} else if (toolList.length > 0) {
				let url = this.$router.resolve({
					name: 'aicreate',
					query: {
						id: toolList[0].id
					}
				})
				window.open(url.href, '_blank')
			} else {
				this.$message.info('未获取到工具，请稍后重试')
			}
		},
		handleToCreate () {
			if (this.toolList && this.toolList.length > 0) {
				let url = this.$router.resolve({
					name: 'aicreate',
					query: {
						id: this.toolList[0].id
					}
				})
				window.open(url.href, '_blank')
			}else {
				this.$message.info('未获取到工具，请稍后重试')
			}
		},
		 // 获取创作历史
		getFileList(){
			this.$http.get('/file/list?page=1&limit=3')
			.then(res => {
				if (res.code === 200) {

					this.fileList = res.data.data || []
				}
			})
		},
		// 获取热门app
		getAppList () {
			this.$http.get('/app/list?page=1&limit=99&pc_enable=true')
			.then(res => {
				if (res.code === 200) {
					let suno = {
						name: '音乐创作',
						remark: '一款ai音乐创作工具',
						avatar: '/img/sunologo.jpeg',
						type: 'suno-app'
					}


					this.toolList = (res.data.data.sort((a, b) => (a.sort - b.sort)) || []).concat([suno])

					storage.set('applist', this.toolList)
				}

			})
		},
		filterCommandChange (command) {
			this.filterCommand = command
		},
		setStyleChecked (item) {
			if (this.styleChecked === item.id) {
				this.styleChecked = -1
				this.$refs.waterfallRef.filter('')
			} else {
				this.styleChecked = item.id
				this.$refs.waterfallRef.filter(item.id)
			}


		},
		toCreate ({id, type}) {
			if (type === 'suno-app') {
				let url = this.$router.resolve({name: 'suno', query: {id}})
				window.open(url.href, '_blank')
			} else {
				if (typeof id !== "undefined" && !!id) {
					let url = this.$router.resolve({name: 'aicreate', query: {id}})
					window.open(url.href, '_blank')
				}
			}
		}
	}
}
</script>

<style scoped lang="less">
.home {
	overflow: hidden;
	padding: 32px 16px 0;
	background: url("../assets/image/bg_lefttop.png") no-repeat -160px -200px;
	background-clip: padding-box;
}
.start {
	height: 148px;
	border-radius: 12px;
	border: 2px dashed #D8DAE8;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	.bg {
		width: 70px;
		height: 70px;
		background: url("../assets/image/dosomething.png") no-repeat center center;
	}

	.desc {
		height: 70px;
		text-align: left;
		margin-left: 16px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&-t {
			color: #242726;
			font-size: 24px;
			font-weight: 400;
			line-height: 34px;
		}

		p {
			color: #868A90;
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
		}
	}
}

.start:hover {
	box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
}

.history {
	height: 148px;

	.title {
		height: 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.text {
			font-size: 24px;
			color: #242726;
			font-weight: 400;
		}
	}

	.history-list {
		display: flex;
		flex-wrap: nowrap;
		height: 108px;
		justify-content: left;
		.history-item {
			width: 30%;
			background-color: #fff;
			border-radius: 12px;
			overflow: hidden;
			cursor: pointer;
			transition: box-shadow 0.2s;
			margin-right: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			> img {
				min-width: 100%;
				min-height: 100%;
			}
		}
		.history-item:hover {
			box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.12);
		}
	}
}

.advertisement {
	height: 148px;
	border-radius: 12px;
	cursor: pointer;
	transition: box-shadow 0.2s;
	.adver-img {
		height: 148px;
		width: 100%;
		>img {
			max-width: 100%;
			height: 148px;
		}
	}
}

.advertisement:hover {
	box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.12);
}


.tools {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 40px;
	margin-top: 32px;
	margin-bottom: 20px;

	.tools-text {
		font-size: 28px;
		color: #242726;
	}
}

.tool-list {
	display: flex;
	justify-content: left;
	flex-wrap: wrap;
}

.tool-item {
	cursor: pointer;
	box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
	height: 176px;
	width: 176px;
	margin-right: 16px;
	margin-bottom: 16px;
	border-radius: 12px;
	overflow: hidden;
	//background: url("../assets/image/toolbg2.png") no-repeat center center;
	position: relative;
	z-index: 1;
	.tool-item-header {
		padding: 0 10px;
		z-index: 4;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		background: rgba(0,0,0,.5);
		.title {
			font-size: 24px;
			color: #fff;
			text-indent: 12px;
			height: 34px;
			line-height: 34px;
			margin-top: 12px;
		}

		.desc {
			font-size: 14px;
			color: #fff;
			text-indent: 12px;
			height: 20px;
			line-height: 20px;
			margin-top: 4px;
			margin-bottom: 6px;
		}
	}


	.img {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		height: 100%;
		width: 100%;
		z-index: 2;
		img {
			height: 100%;
			width: 100%;
		}
	}
}

.tool-item-first {
	display: flex;
	flex-direction: row;
	width: 362px;
	//background: url("../assets/image/toolbg1.png") no-repeat center center;
	.tool-item-header {
		width: 50%;
		padding: 0 10px;
		background: rgba(0,0,0,0);
		.title, .desc {
			color: #242726;
		}

	}

	.img {
		left: 50%;
		width: 50%;
	}
}

.more, .filter {
	color: #9DA3AE;
	font-size: 14px;
	cursor: pointer;
	> i {
		font-size: 12px;
	}

}
.filter {
	border: 1px solid #D8DAE8;
	border-radius: 16px;
	height: 32px;
	padding: 0 12px;
	display: flex;
	align-items: center;
}

.style-list {
	display: flex;
	justify-content: left;
	align-items: center;
	margin-bottom: 16px;
	.style-item {
		font-size: 18px;
		color: #242726;
		padding: 9px 16px;
		margin: 0 14px;
		cursor: pointer;
		height: 42px;
		border-radius: 21px;
		transition: background 0.2s;
	}
	.style-item.checked {
		color: #fff;
		background: rgba(51,51,51,1);
	}
	.style-item:hover {
		color: #fff;
		background: rgba(51,51,51,0.5);
	}

}















</style>
