import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/icon/iconfont.css'
import store from "@/store";
import serves from "@/util/request";
import axios from './util/request'
import storage from './util/storage'
import VueWebCam from 'vue-web-cam'
// import { LazyImg, Waterfall } from 'vue-waterfall-plugin'

import './assets/css/global.less'

import 'xgplayer/dist/index.min.css';
import './util/globalComponent'

import t_cn from './util/t-cn'

import { GenerateForm, MakingForm } from 'form-making';
import 'form-making/dist/FormMaking.css';
Vue.use(GenerateForm);
Vue.use(MakingForm);


Vue.prototype.$18n = t_cn

Vue.use(VueWebCam)

Vue.use(ElementUI);

Vue.prototype.$http = serves

Vue.config.productionTip = false



new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')


