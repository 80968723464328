<template>
	<div class="page page-user">



		<el-table
			:data="userList"
			stripe
			size="small"
			border
			style="width: 100%;height:100%;"
		>
			<el-table-column
				align="center"
				prop="username"
				label="用户名"></el-table-column>
			<el-table-column align="center"
				prop="nickname"
				label="昵称"></el-table-column>
			<el-table-column align="center"
				prop="phone"
				label="手机号"></el-table-column>
			<el-table-column align="center"
				prop="email"
				label="邮箱">
			</el-table-column>
<!--			<el-table-column-->
<!--				prop="size"-->
<!--				label="大小"-->
<!--				width="120">-->
<!--				<template slot-scope="{ row }">-->
<!--					{{ row.type === 'directory' ? '' : bytesToSize(row.size) }}-->
<!--				</template>-->
<!--			</el-table-column>-->
			<el-table-column align="center"
				prop="create_at"
				label="创建时间">
			</el-table-column>
			<el-table-column align="center"
				prop="action"
				label="操作">
				<template slot-scope="{ row }">
					<el-button size="small" type="text"
							   @click="handlePasswordReset(row)">重置密码
					</el-button>

				</template>
			</el-table-column>
		</el-table>

	</div>
</template>

<script>
export default {
	name: "user-manage",
	data () {
		return {
			userList: [{}]
		}
	},
	methods: {
		handlePasswordReset () {},

	}
}
</script>

<style scoped>
.page-user {
	padding: 20px;
}
</style>
