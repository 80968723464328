const app = 'ACME-APP'

function get (key) {
    let parse = JSON.parse(window.localStorage.getItem(app)) || {};
    if (parse[key]) {
        return parse[key]
    }
    return null
}

function set(key, val) {
    let parse = JSON.parse(window.localStorage.getItem(app)) || {};
    parse[key] = val
    window.localStorage.setItem(app, JSON.stringify(parse));
    return parse
}

function remove (key) {
    if (typeof key === 'undefined') {
        return null
    } else if (key === '_all') {
        window.localStorage.removeItem(app)
        return null
    }
    let parse = JSON.parse(window.localStorage.getItem(app)) || {};
    if (parse[key]) {
        delete parse[key]
        window.localStorage.setItem(app, JSON.stringify(parse));
        return parse
    } else {
        return null
    }
}

const storage = {
    get, set, remove
}

export default storage
