import Vue from 'vue'

const user = {

    state: {
        userInfo: {},
        showAside: false,
        fullscreen: false,
        likeList: [],
        favoriteList: []
    },

    mutations: {
        set_user_info: (state, info) => {
            state.userInfo = info || {}
        },
        set_aside_show(state, showAside) {
            state.showAside = showAside
        },
        set_full_screen(state, fullscreen) {
            state.fullscreen = fullscreen
        },
        set_like_list (state, list) {
            state.likeList = list
        },
        set_favorite_list (state, list) {
            state.favoriteList = list
        },

    },

    actions: {
        getUserInfo ({commit, state}) {
            Vue.prototype.$http.get('/user/profile')
                .then(res=> {
                    if (res.data && res.data.id) {
                        commit('set_user_info', res.data)
                    }
                })
        },
        getLikeList ({commit, state}) {
            return new Promise(resolve => {
                Vue.prototype.$http.get('/file/list/like?page=1&limit=9999')
                    .then(res=> {
                        if (res.code === 200) {
                            commit('set_like_list', res.data.data)
                        }
                    })
            })

        },
        getFavoriteList ({commit, state}) {
            Vue.prototype.$http.get('/file/list/favorite?page=1&limit=9999')
                .then(res=> {
                    if (res.code === 200) {
                        commit('set_favorite_list', res.data.data)
                    }
                })
        },
    }
}

export default user
