<template>
	<div class="page page-forget">
		<Header />

		<div class="login-container">
			<div class="title">
				<div class="logo"><img src="../assets/image/wanxianglogo2.png" alt=""></div>
			</div>
			<el-form ref="form" label-width="80px" :model="form" :rules="rules" :label-position="'left'">
				<el-form-item label="手机号:" prop="phone">
					<el-input v-model="form.phone" >
						<template slot="prepend">+86</template>
					</el-input>
				</el-form-item>
				<el-form-item label="验证码:" prop="code">
					<el-input v-model="form.code">
						<template slot="append">
							<span class="code-button" v-show="codeStatus === 1" @click="handleSendCode">发送验证码</span>
							<span class="code-button" v-show="codeStatus === 2" >{{ codeSeconds }}</span>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item label="新密码:" prop="password">
					<el-input v-model="form.password" type="password" show-password ></el-input>
				</el-form-item>

			</el-form>
			<div class="submit">
				<el-button :loading="loading" type="primary" @click="handleSubmit">提交</el-button>
			</div>
		</div>
	</div>
</template>


<script>
import Header from "@/view/layout/Header";
export default {
	name: "forget",
	components: {Header},
	data () {
		return {
			loading: false,
			codeStatus: 1,
			codeSeconds: 60,
			form: {
				phone: '',
				code: '',
				password: ''
			},
			rules: {
				phone: [
					{required: true, message: '请输入手机号', trigger: 'blur'},
					// {pattern: /^1[3-9]\d{9}$/, message: '输入的手机号不正确', trigger: 'blur'}
				],
				code: [
					{required: true, message: '请输入验证码', trigger: 'blur'},
				],
				password: [
					{required: true, message: '请输入密码', trigger: 'blur'},
				],
			}
		}
	},
	methods: {
		handleSubmit () {
			const {phone,password,code} = this.form
			this.$refs.form.validate( (valid) => {
				if (!valid) {
					return
				}
				this.$http.post('/user/reset/passwd', {
					new_passwd: password,
					phone,
					captcha: code,
				}).then(res => {
					this.$router.push({name: 'login'})
				})
			})
		},
		handleSendCode () {
			this.$refs.form.validateField(['phone'], (valid) => {
				if (valid) {
					return
				}
				this.$http.post('/smcode/send', {mobile: this.form.phone, biz: 'passwd'})
					.then(res => {
						if (res.code === 200) {
							this.codeStatus = 2
							this.codeSeconds = 60
							this.handleSeconds()
						} else {

						}
					})
			})
		},
		handleSeconds() {
			if (this.codeSeconds === 0) {
				this.codeStatus = 1
			} else {
				setTimeout(() => {
					this.codeSeconds--
					this.handleSeconds()
				}, 1000)
			}
		},
	}
}
</script>

<style lang="less">
@import "../assets/css/user.less";
</style>
