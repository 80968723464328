<template>
	<div class="page page-register">
		<Header />

		<div class="login-container">
			<div class="title">
				<div class="logo"><img src="../assets/image/wanxianglogo2.png" alt=""></div>
			</div>
			<el-form ref="form" label-width="80px" :model="form" :rules="rules" :label-position="'left'">
				<el-form-item label="手机号:" prop="phone">
					<el-input v-model="form.phone" >
						<template slot="prepend">+86</template>
					</el-input>
				</el-form-item>
				<el-form-item label="验证码:" prop="code">
					<el-input v-model="form.code">
						<template slot="append">
							<span class="code-button" v-show="codeStatus === 1" @click="handleSendCode">发送验证码</span>
							<span class="code-button" v-show="codeStatus === 2" >{{ codeSeconds }}</span>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item label="用户名:" prop="username">
					<el-input v-model="form.username" ></el-input>
				</el-form-item>
				<el-form-item label="密码:" prop="password">
					<el-input v-model="form.password" type="password" show-password ></el-input>
				</el-form-item>
				<el-form-item label="邮箱:" prop="email">
					<el-input v-model="form.email" ></el-input>
				</el-form-item>

			</el-form>
			<div class="submit">
				<el-button :loading="loading" type="primary" @click="handleSubmit">提交</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import Header from "@/view/layout/Header";
export default {
	name: "register",
	components: {Header},
	data () {
		return {
			loading:false,
			codeStatus: 1, // 1未发送 2发送中
			codeSeconds: 60,
			form: {
				password: '',
				phone: '',
				email: '',
				captcha: '',
				username: ''
			},
			rules: {
				phone: [
					{required: true, message: '请输入手机号', trigger: 'blur'},
					{pattern: /^1[3-9]\d{9}$/, message: '输入的手机号不正确', trigger: 'blur'}
				],
				code: [
					{required: true, message: '请输入验证码', trigger: 'blur'},
				],
				username: [
					{required: true, message: '请输入用户名', trigger: 'blur'},
				],
				password: [
					{required: true, message: '请输入密码', trigger: 'blur'},
				],
				email: [
					{required: true, message: '请输入邮箱', trigger: 'blur'},
					{pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: '邮箱格式不正确', trigger: 'blur'},
				],
			}
		}
	},
	methods: {
		handleSendCode () {
			this.$refs.form.validateField(['phone'], (valid) => {
				if (valid) {
					return
				}
				// setTimeout(() => {
				// 	this.codeStatus = 2
				// 	this.codeSeconds = 60
				// 	this.handleSeconds()
				// }, 1000)
				this.$http.post('/smcode/send', {mobile: this.form.phone, biz: 'registry'})
					.then(res => {
						if (res.code === 200) {
							this.codeStatus = 2
							this.codeSeconds = 60
							this.handleSeconds()
						} else {

						}
					})
			})
		},
		handleSeconds() {
			if (this.codeSeconds === 0) {
				this.codeStatus = 1
			} else {
				setTimeout(() => {
					this.codeSeconds--
					this.handleSeconds()
				}, 1000)
			}
		},
		handleSubmit () {
			const {phone,password,email,code,username} = this.form
			this.$refs.form.validate( (valid) => {
				console.log(valid)
				if (!valid) {
					return
				}
				this.$http.post('/user/registry', {
					password,
					phone,
					email,
					captcha: code,
					username,
					// nickname: '用户' + Date.now()
				}).then(res => {
					if (res.code === 200) {
						this.$router.push({name: 'login'})
					}
				})

			})
		}
	}
}
</script>

<style lang="less">
@import "../assets/css/user.less";
</style>
