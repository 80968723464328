<template>
	<div class="upload-container">
		<input v-if="!showRecording" type="file" accept="video/*" ref="fileInput" @change="handleFileUpload" style="display: none;">
		<div v-show="!showRecording"  class="dropzone" @dragover.prevent @dragenter.prevent @drop="handleDrop" @click="openFileDialog">

			<div v-show="uploadedFileUrl" class="preview">
				<video :src="uploadedFileUrl" controls  playsinline  width="300" height="200"></video>
			</div>
			<div class="upload-info" v-show="!uploadedFileUrl">
				<i class="el-icon-upload"></i>
				<p>拖拽视频到这里或者点击上传</p>
			</div>
		</div>
		<Recording v-if="showRecording" @complete="completeRecording"/>

		<div class="upload-type">
			<div class="type-item" @click="showUpload">
				<i class="el-icon-upload"></i>
			</div>
			<div class="type-item" @click="showMyFile">
				<i class="el-icon-folder-opened"></i>
			</div>
			<div  class="type-item"  @click="recordVideo">
				<i class="el-icon-video-camera"></i>
			</div>
		</div>
		<VideoList :videos="example" @select="selectFromExample"/>
		<MyFileDialog ref="myFileRef" @select="handleFileFromFile"/>
	</div>
</template>

<script>
import MyFileDialog from "@/components/MyFileDialog";
import Recording from "@/components/Recording";
import CameraImage from "@/components/CameraImage";
import VideoList from "@/components/VideoList";
import {uploadFile} from "@/util/request";
import {getMediaType} from "@/util";
export default {
	components: {MyFileDialog, Recording, CameraImage, VideoList},
	props: {
		example: {
			type: Array,
			default: () => ([])
		},
		defaultUrl: {
			type: String,
			default: ''
		}
	},
	watch: {
		defaultUrl (val) {
			console.log('===video defaultUrl===', val)
			if (val) {
				this.uploadedFileUrl = val
			}
		}
	},
	data() {
		return {
			uploadedFileUrl: '',
			mediaUrl: '',
			type: 'upload', // 获取资源的来源类型， upload:选本地文件 file:我的媒体库 camera：录像 recording拍照
			showRecording: false,
			id: ''
		};
	},
	methods: {
		showMyFile () {
			this.$refs.myFileRef.show()
			this.showCamera = false
		},
		selectFromExample (path) {
			if (path) {
				this.uploadedFileUrl = path
				this.emitChange()
			} else {
				this.$message.warning('未获取到文件')
			}
		},
		handleComplete (path) {
			if (path) {
				this.uploadedFileUrl = path
				this.emitChange()
			} else {
				this.$message.warning('未获取到文件')
			}
		},
		emitChange () {
			this.$emit('change', this.uploadedFileUrl, this.id)
		},
		openFileDialog() {
			this.$refs.fileInput.click();
		},
		handleFileUpload(event) {
			const file = event.target.files[0];
			if (file) {
				uploadFile(file)
				.then(res => {
					this.uploadedFileUrl = res.url;
					this.id = res.id
					this.emitChange()
				})

			}
		},
		handleDrop(event) {
			event.preventDefault();
			const files = event.dataTransfer.files;

			if (files.length > 0) {
				const file = files[0];
				if (!file.type.startsWith('video/')) {
					this.$message.warning('请上传视频文件')
				} else {
					this.uploadedFileUrl = URL.createObjectURL(file);
					this.emitChange()
				}

			}
		},
		showUpload () {
			this.uploadedFileUrl = ''
			this.showRecording = false
		},
		recordVideo() {
			this.showRecording = true
		},
		completeRecording(url, id) {
			this.showRecording = false
			this.uploadedFileUrl = url
			this.id = id
			this.emitChange()
		},
		handleFileFromFile (url, id) {
			// todo：判断是否是视频文件
			if (getMediaType(url) !== 'video') {
				this.$message.warning('请选择视频类文件')
				return
			}
			this.uploadedFileUrl = url
			this.id = id
			this.emitChange()
		}
	},
};
</script>

<style scoped lang="less">
.upload-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.dropzone {
	width: 300px;
	height: 200px;
	border: 1px dashed #ccc;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.dropzone:hover {
	border: 1px dashed #409eff;
}

.dropzone i {
	font-size: 48px;
	margin-bottom: 10px;
}

.dropzone p {
	font-size: 16px;
	color: #aaa;
}

.upload-info {
	color: #999;
	flex-direction: column;
	display: flex;
	justify-content: center;align-items: center;
}

.preview {
	max-width: 100%;
	max-height: 100%;

	> img {
		max-width: 100%;
		max-height: 100%;
	}
}
.upload-type {
	display: flex;
	color: #409eff;
	justify-content: space-around;
	font-size: 18px;
	> .type-item {
		margin: 0 8px;
		cursor: pointer;
	}
}

</style>
