import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/view/Home";
import Info from "@/view/Info";
import Layout from "@/view/layout/Layout";
import AiCreate from "@/view/AiCreate";
import Login from '@/view/Login'
import Register from "@/view/register";
import Forget from "@/view/forget";
import User from "@/view/manage/User";
import APP from "@/view/manage/APP";
import SunoIndex from "@/view/suno/Index"
import FilePreview from "@/view/filepreview";

Vue.use(VueRouter)

const routes = [

    // {
    //     path: '/app',
    //     name: 'app',
    //     meta: {
    //         title: '模型管理'
    //     },
    //     component: APP
    // },

    // {
    //     path: '/user',
    //     name: 'user',
    //     meta: {
    //         title: '用户管理'
    //     },
    //     component: User
    // },


    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登录'
        },
        component: Login
    },
    {
        path: '/register',
        name: 'register',
        meta: {
            title: '注册'
        },
        component: Register
    },
    {
        path: '/forgt',
        name: 'forget',
        meta: {
            title: '忘记密码'
        },
        component: Forget
    },
    {
        path: '/aicreate',
        name: 'aicreate',
        meta: {
            title: 'AI创作'
        },
        component: AiCreate
    },
    {
        path: '/suno',
        name: 'suno',
        meta: {
            title: '音乐创作'
        },
        component: SunoIndex
    },
    {
        path: '/filepreview/:id',
        name: 'filepreview',
        meta: {
            title: '预览'
        },
        component: FilePreview
    },
    {
        path: '/about',
        name: 'about',
        meta: {
            title: '关于我们'
        },
        component: () => import('../view/About')
    },
    {
        path: '/industry',
        name: 'industry',
        meta: {
            title: '行业咨询'
        },
        component: () => import('../view/Industry')
    },

    {
        path: '/',
        component: Layout,
        redirect: 'home',
        children: [
            {
                path: '/',
                name: 'home',
                meta: {
                    title: '首页'
                },
                component: Home
            },

            {
                path: 'info',
                name: 'info',
                meta: {
                    title: '个人信息'
                },
                component: Info
            },
            {
                path: 'myfile',
                name: 'myfile',
                meta: {
                    title: '我的文件'
                },
                component: () => import('../view/MyFileView')
            },
            {
                path: 'apps',
                name: 'apps',
                meta: {
                    title: '工具广场'
                },
                component: () => import('../view/Apps')
            },
            {
                path: 'feedback',
                name: 'feedback',
                meta: {
                    title: '反馈建议'
                },
                component: () => import('../view/Feedback')
            },

        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {

    next()
})

router.afterEach((to, frome) => {
    let title = to?.meta?.title || '万象AI'
    if (title !== '万象AI') {
        title += '-万象AI'
    }
    document.title = title
})

export default router
