<template>
	<div class="page page-file-preview" v-if="visible">
		<div class="preview-container" :key="id">

			<div class="header">
				<div class="header-left">
					<div class="header-item" @click="visible = false"><i class="el-icon-close"></i></div>
				</div>
				<div class="header-right">
					<!--					<div class="header-item" @click="handleDownload"> <i class="el-icon-download"></i> </div>-->
				</div>
			</div>

			<div class="file">
				<div class="file-left" @click="toPre" v-if="currentIndex > 0">
					<i class="el-icon-arrow-left"></i>
				</div>
				<div class="file-left disabled" v-else title="当前已经是第一个">
					<i class="el-icon-arrow-left"></i>
				</div>
				<div class="file-view">

					<img :src="currentFile?.href" alt="" :key="id">


					<div class="info">
						<div class="info-item download">
							<i class="el-icon-download" @click="handleDownload" style="font-size: 18px;"></i>
						</div>
						<div class="info-item share">
							<i class="el-icon-share"></i>
						</div>
						
						<div class="info-item good" v-if="typeof currentFile.like !== 'undefined'">
							<i @click="handleLike(currentFile, -1)"
							   v-if="likeList.map(i => i.id).indexOf(currentFile?.id) > -1" style="color: #FFb900;"
							   class="iconfont icon-dianzan"></i>
							<i @click="handleLike(currentFile, 1)" v-else class="iconfont icon-dianzan"></i>

							<span style="padding: 0 2px;"></span>


							<span v-if="likeList.map(i => i.id).indexOf(currentFile?.id) > -1" style="color: #FFb900;">{{
									currentFile?.like || 0
								}}</span>
							<span v-else>{{ currentFile?.like || 0 }}</span>

						</div>
						<div class="info-item collect" v-if="typeof currentFile.favorite !== 'undefined'">
							<i @click="handleFavorite(currentFile, -1)"
							   v-if="favoriteList.map(i => i.id).indexOf(currentFile?.id) > -1" style="color: #FFb900;"
							   class="iconfont icon-shoucang"></i>
							<i @click="handleFavorite(currentFile, 1)" v-else class="iconfont icon-shoucang"></i>

							<span style="padding: 0 2px;"></span>

							<span v-if="favoriteList.map(i => i.id).indexOf(currentFile?.id) > -1"
								  style="color: #FFb900;">{{ currentFile?.favorite || 0 }}</span>
							<span v-else>{{ currentFile?.favorite || 0 }}</span>
						</div>
					</div>

				</div>
				<div class="file-right" @click="toNext" v-if="currentIndex < fileList.length - 1">
					<i class="el-icon-arrow-right"></i>
				</div>
				<div class="file-right disabled" v-else title="当前已经是最后一个">
					<i class="el-icon-arrow-right"></i>
				</div>
			</div>


		</div>
		<div class="file-info"></div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import storage from "@/util/storage";

export default {
	name: "file-preview",
	data() {
		return {
			fileList: [],
			id: 1,
			visible: false
		}
	},
	computed: {
		...mapGetters(['likeList', 'favoriteList']),
		currentIndex() {
			const {id, fileList} = this
			return fileList.findIndex(i => i.id === id)
		},
		currentFile() {
			const {id, fileList} = this
			return fileList.find(i => i.id === id)
		}
	},
	created() {
		// this.fileList = storage.get('file_preview_list') || []
		// let id = this.$route.params.id
		//
		// if (id) {
		// 	this.id = id * 1
		// }
	},
	mounted() {
	},
	methods: {
		show(fileList = [], id) {
			this.visible = true
			this.fileList = fileList
			this.id = id
		},

		fileDetail (id) {
			this.$http.get('/file/detail/' + id)
			.then(res => {
				if (res.code === 200) {
					let index = this.fileList.findIndex(i => i.id === res.data.id)
					if (index > -1) {
						this.fileList.splice(index, 1, res.data)
						console.log(index, res.data.id, this.fileList)
					}
				}
			})
		},

		handleLike(file, number = 0) {
			this.$http.post('/file/sms/like', {file_id: file.id})
				.then(res => {
					if (res.code === 200) {
						this.$store.dispatch('getLikeList')
						this.fileDetail(file.id)
						// for (let i = 0; i < this.fileList.length; i++) {
						// 	if (this.fileList[i].id === file.id) {
						// 		this.$set(this.fileList[i], 'like', this.fileList[i].like + number)
						// 		break
						// 	}
						// }
					}
				})
		},
		handleFavorite(file, number) {
			this.$http.post('/file/sms/favorite', {file_id: file.id})
				.then(res => {
					if (res.code === 200) {
						this.$store.dispatch('getFavoriteList')
						this.fileDetail(file.id)
						// for (let i = 0; i < this.fileList.length; i++) {
						// 	if (this.fileList[i].id === file.id) {
						// 		this.$set(this.fileList[i], 'favorite', this.fileList[i].favorite + number)
						// 		break
						// 	}
						// }
					}
				})
		},
		toPre() {
			const {currentIndex, fileList} = this
			this.id = fileList[currentIndex - 1]?.id

		},
		toNext() {
			const {currentIndex, fileList} = this
			this.id = fileList[currentIndex + 1]?.id
		},
		handleDownload() {
			const link = document.createElement('a');
			link.href = this.currentFile.href;
			link.download = ''; // 设置下载文件的名称，如果留空则使用URL中的文件名
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

}
</script>

<style scoped lang="less">
.page-file-preview {
	position: fixed;
	top: 66px;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10000;
	background: #f6f7fa;

	.preview-container {
		height: calc(100vh - 67px);
	}

	.file-info {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 0;

	}
}

.preview-container {
	.header {
		height: 60px;
		padding: 12px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.header-item {
			height: 36px;
			width: 36px;
			border-radius: 18px;
			background: rgba(52, 58, 64, 0.5);
			color: #fff;
			font-size: 24px;
			text-align: center;
			line-height: 36px;
			cursor: pointer;
		}
	}

	.file {
		height: calc(100vh - 127px);
		position: relative;
		padding: 10px 56px;

		.file-view {
			//position: absolute;
			//top: 50%;
			//left: 50%;
			//transform: translate3d(-50%, -50%, 0);
			height: 100%;
			//width: calc(100% - 72px);
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			> img {
				max-width: 100%;
				max-height: calc(100% - 30px);
			}

			.action {
				display: flex;
				justify-content: center;
				align-items: center;

			}

			.info {
				height: 36px;
				margin-top: 4px;
				display: flex;
				justify-content: center;
				color: #666;

				.info-item {
					cursor: pointer;
				}

				.info-item:nth-child(n+1){
					margin-left: 10px;
				}

				.info-item.good {
					margin-left: 30px;
				}
			}
		}

		.file-left, .file-right {
			position: absolute;
			top: 50%;
			transform: translate3d(0, -50%, 0);
			width: 36px;
			height: 36px;
			border-radius: 18px;
			background: rgba(52, 58, 64, 0.5);
			color: #fff;
			font-size: 24px;
			text-align: center;
			line-height: 36px;
			cursor: pointer;
		}

		.file-left {
			left: 20px;
		}

		.file-right {
			right: 20px;
		}

		.file-left.disabled, .file-right.disabled {
			cursor: not-allowed;
			background: rgba(52, 58, 64, 0.2);
		}
	}
}

</style>
