<template>
	<div class="recording-container">
		<video v-show="!recordedVideo"  autoplay playsinline ref="preview" width="300" height="200" ></video>
		<el-button type="text" size="small" class="record-btn record-btn2" v-if="!!stream" @click="stopRecording">停止录像</el-button>
		<el-button type="text" size="small" class="record-btn record-btn1" v-else @click="startRecording">开始录像</el-button>
	</div>
</template>

<script>
import {uploadFile} from "@/util/request";

export default {
	data() {
		return {
			recordedBlob: null,
			mediaRecorder: null,
			recordedVideo: null,
			stream: null,
			downloadUrl: ''
		};
	},
	methods: {
		async startRecording() {
			const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
			this.stream = stream;
			this.$refs.preview.srcObject = stream;
			this.mediaRecorder = new MediaRecorder(stream);
			const chunks = [];
			this.mediaRecorder.addEventListener("dataavailable", (event) => {
				chunks.push(event.data);
			});
			this.mediaRecorder.addEventListener("stop", () => {
				const blob = new Blob(chunks, { type: "video/webm" });
				const file = new File([blob], 'test.webm',  { type: 'video/webm' })
				this.downloadUrl = URL.createObjectURL(blob)
				uploadFile(file)
				.then(res => {
					this.$emit('complete', res.url, res.id)
				})

			});
			this.mediaRecorder.start();
		},

		blobToFile(blob, fileName = Date.now() + '.mp4') {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onloadend = () => {
					const dataUrl = reader.result;
					const file = new File([blob], fileName, { type: blob.type, lastModified: Date.now() });
					file.url = dataUrl;
					console.log(file)
					resolve(file);
				};
				reader.onerror = (error) => {
					reject(error);
				};
				// reader.readAsDataURL(blob);
			});
		},

		stopRecording() {
			if (this.mediaRecorder) {
				this.mediaRecorder.stop();
			}
			if (this.stream) {
				this.stream.getTracks().forEach((track) => track.stop());
				this.stream = null;
			}
		},
	},
};
</script>
<style scoped lang="less">
	.recording-container {
		width: 300px;
		height: 200px;
		position: relative;
		border-radius: 5px;
		.record-btn {
			position: absolute;
		}
		.record-btn1 {
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);
		}
		.record-btn2 {
			bottom: 5px;
			right: 5px;
		}

	}
</style>
