<template>
	<div class="info">
		<div class="header">
			<div class="ainame">个人信息</div>
			<div class="back" @click="$router.go(-1)"><i class="iconfont icon-lujing"></i> 返回</div>
		</div>
		<div class="info-container">
			<el-form ref="form" label-width="100px" :model="form">
				<el-form-item label="头像:" prop="avatar">
					<div class="avatar-container">
						<input type="file" @change="handleFileChange" accept="image/*">
						<el-avatar  :size="50" :src="form.avatar"/>
					</div>
				</el-form-item>
				<el-form-item label="昵称:" prop="nickname" >
					<el-input v-model="form.nickname">
					</el-input>
				</el-form-item>
				<el-form-item label="手机号:" prop="phone">
					<el-input v-model="form.phone" disabled>
					</el-input>
				</el-form-item>
<!--				<el-form-item label="密码:" prop="code">-->
<!--					<el-input v-model="form.pwd" type="password">-->
<!--					</el-input>-->
<!--				</el-form-item>-->
				<el-form-item label="邮箱:" prop="email">
					<el-input v-model="form.email">
					</el-input>
				</el-form-item>
			</el-form>
			<div class="btns"><el-button type="primary" size="small" @click="handleSubmit">提交</el-button></div>
		</div>
	</div>
</template>

<script>
import { gradioRequest } from "@/util/gradioRequest";
import {mapGetters} from "vuex";

export default {
	name: "info",
	data() {
		return {
			form: {
				nickname: '',
				phone: '',
				pwd: '',
				email: '',
				avatar: ''
			}
		}
	},
	computed: {
		...mapGetters(['userInfo'])
	},
	watch: {
		userInfo ({avatar, email, nickname, phone}) {
			this.form.avatar = avatar
			this.form.email = email
			this.form.nickname = nickname
			this.form.phone = phone
		}
	},
	created() {
		this.$store.dispatch('getUserInfo')


	},
	methods: {
		handleSubmit () {
			this.$http.put('/user/edit/' + this.userInfo.id, { ...this.form })
			.then(res => {
				this.$store.dispatch('getUserInfo')
			})
		},
		handleFileChange (event) {
			const file = event.target.files[0];
			if (file) {
				const formData = new FormData();
				formData.append('file', file);
				this.$http.post('/file/upload', formData, {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				})
					.then(response => {
						console.log(response)
						if (response.code === 200) {
							this.form.avatar = response.data.href
						}
					})
					.catch(error => {

					});
			}
		}
	}
}
</script>

<style scoped lang="less">

.info-container {
	width: 500px;
	margin: 20px auto 0;
	min-height: calc(100vh - 160px);
	background: #fff;
	padding: 20px 40px 20px 20px;
}

.header {
	display: flex;
	justify-content: left;
	margin: 20px 0 0 20px;

	.ainame {
		font-size: 24px;
		color: #333;
		font-weight: 400;
	}

	.back {
		height: 34px;
		line-height: 34px;
		width: 83px;
		border: 1px solid #D8DAE8;
		background: #FFFFFF;
		display: flex;
		justify-content: center;
		//align-items: center;
		border-radius: 8px;
		margin: 0 28px;
		font-size: 18px;
		cursor: pointer;

		> i {
			rotate: 180deg;
			font-size: 14px;
			margin-right: 4px;
			transform: translateY(-2px);
		}
	}
}

.btns {
	display: flex;
	justify-content: center;
	margin-top: 100px;
}

.avatar-container {
	height: 50px;
	width: 50px;
	position: relative;
	> input[type=file] {
		position: absolute;
		opacity: 0;
		width: 50px;
		height: 50px;
		border-radius: 25px;
		cursor: pointer;
	}
}
</style>
