<template>
	<div class="xg-player" ref="xgPlayerRef">
	</div>
</template>

<script>
import XGPlayer from 'xgplayer'
export default {
	name: "xg-player",
	props: {
		src: {
			type: String,
			require: true
		},
		options: {
			type: Object,
			default: () => ({})
		}
	},
	data () {
		return {
			player: null
		}
	},
	mounted() {
		this.player = new XGPlayer({
			el: this.$refs.xgPlayerRef,
			url: this.src, // 视频文件的路径
			width: '100%',

			presets: ['default'],
			...this.options
		});
	}
}
</script>

<style scoped>

</style>
