<template>
	<div id="app" @click="handleBodyEvent">
		<transition name="fade" mode="out-in">
			<router-view/>
		</transition>

<!--		<CreateTaskList ref="createTaskListRef" />-->
	</div>
</template>

<script>
import vm from './util/eventUtil'
import CreateTaskList from "@/components/CreateTaskList";

export default {
	name: 'App',
	components: {CreateTaskList},
	data () {
		return {
			timeout: null
		}
	},
	mounted() {
		document.addEventListener("fullscreenchange", () => {
			if (!document.fullscreenElement) {
				this.$store.commit('set_full_screen', false)
			}
		});

		this.$store.dispatch('getUserInfo')
		this.$store.dispatch('getLikeList')
		this.$store.dispatch('getFavoriteList')

		// 全局注册窗口变化事件
		window.onresize = () => {
			vm.$emit('window-resize')
			// if (this.timeout) {
			// 	clearTimeout(this.timeout)
			// }
			// this.timeout = setTimeout(() => {
			//
			// },10)

		}

	},
	methods: {
		handleBodyEvent() {
			this.$store.commit('set_aside_show', false)
		}
	}
}
</script>

<style>
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	overflow-x: auto;
}

.fade-enter-active, .fade-leave-active {
	transition: opacity .2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
{
	opacity: 0;
}

*::-webkit-scrollbar {
	height: 10px;
	width: 10px;
}

*::-webkit-scrollbar-track {
	background: #f1f1f1; /* 设置滚动条背景颜色 */
}

*::-webkit-scrollbar-thumb {
	background-color: #aaa; /* 设置滚动条颜色 */
	border-radius: 5px; /* 设置滚动条圆角 */
	width: 2px
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.el-table__header {
	width: 100% !important;
}

.el-table__body {
	width: 100% !important;
}

.lazy__img[lazy=loading] {
	padding: 5em 0;
	width: 48px;
}

.lazy__img[lazy=loaded] {
	width: 100%;
}

.lazy__img[lazy=error] {
	padding: 5em 0;
	width: 48px;
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

</style>
