<template>
	<el-dialog
		:visible.sync="visible"
		title="文件编辑"
		append-to-body
		width="1000px"
		@close="handleClose"
		:close-on-click-modal="false">
		<div class="container">
			<div class="left">
				<img v-if="file.mediaType === 'image'" :src="addSmallSuffix(file.href, 'normal')">
				<xg-player v-if="file.mediaType === 'video'" :src="file.href"/>
			</div>
			<div class="right">
				<el-form ref="form" label-width="100px" :model="form">
					<el-form-item label="名称:" >
						<el-input v-model="form.name" size="small" />
					</el-form-item>
					<el-form-item label="分类:" >
						<el-select v-model="form.category" placeholder="请选择分类" size="small">
							<el-option
								v-for="item in categoryList"
								:key="item.id"
								:label="item.category"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="公开:" >
						<el-radio-group v-model="form.public">
							<el-radio :label="0">不公开</el-radio>
							<el-radio :label="1">公开</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" @click="onSubmit">提交</el-button>
					</el-form-item>
				</el-form>

			</div>
		</div>


	</el-dialog>
</template>

<script>
import {getFileCategory} from "@/util/request";
import {addSmallSuffix} from "@/util";

export default {
	name: "file-update",
	data () {
		return {
			visible: false,
			file: {},
			form: {
				name: '',
				category: '',
				public: 0
			},
			categoryList: []
		}
	},
	mounted() {
		getFileCategory().then(res => {
			if (res.code === 200) {
				this.categoryList = res.data.data
			}
		})
	},
	methods: {
		addSmallSuffix,
		show (file) {
			console.log(file)
			this.visible = true
			this.file = file
			this.form.name = file.name
			this.form.category = file.category
			this.form.public = file.public
			getFileCategory().then(res => {
				if (res.code === 200) {
					this.categoryList = res.data.data
				}
			})
		},
		onSubmit () {
			if (!this.form.name) {
				this.$message.warning('请输入文件名称')
				return
			}
			this.$http.post('/file/edit', {
				"file_id": this.file.id,
				"category": this.form.category || '',
				"name": this.form.name,
				"public": this.form.public
			}).then(res => {
				if (res.code === 200) {
					this.handleClose()
					this.$emit('change', res.data)
				}
			})
		},

		handleClose() {
			this.visible = false
			this.form.name = ''
			this.form.category = ''
			this.form.public = ''
		}


	}
}
</script>

<style scoped lang="less">

.container {
	display: flex;
	justify-content: space-between;
	.left, .right {
		width: calc(50% - 5px);
		> img {
			width: 100%;
			height: auto;
		}
	}
}

</style>
