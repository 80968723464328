<template>
	<div class="waterfall3-masonry" id="waterfall3-masonry">
		<div class="item" v-for="item in list" :style="`width:${columnWidth}px;`" :file_id="item.id"  @click="gotoPreview(item)">
<!--			<img :src="addSmallSuffix(i.href, 'normal')" alt="">-->
			<img  v-if="getMediaType(item.href) === 'image'" :src="addSmallSuffix(item.href, 'normal')"  alt="">
<!--			<video style="width: 100%;height: auto;" controls v-if="getMediaType(item.href) === 'video'" :src="item.href" />-->
			<WaterfallVideoItem v-if="getMediaType(item.href) === 'video'" :video-info="item" />

			<div class="info">
				<div class="info-left">
					<!--					<div class="avatar"> </div>-->
					<!--					<div class="username">小花椒 </div>-->
				</div>
				<div class="info-right" @click.stop>
					<div class="good" >
						<i @click="handleLike(item, -1)" v-if="likeList.map(i => i.id).indexOf(item.id) > -1" style="color: #FFb900;" class="iconfont icon-dianzan"></i>
						<i @click="handleLike(item, 1)" v-else class="iconfont icon-dianzan"></i>

						<span style="padding: 0 2px;"></span>


						<span v-if="likeList.map(i => i.id).indexOf(item.id) > -1" style="color: #FFb900;">{{ item?.like || 0 }}</span>
						<span v-else>{{ item?.like || 0 }}</span>

					</div>
					<div class="collect" >
						<i @click="handleFavorite(item, -1)" v-if="favoriteList.map(i => i.id).indexOf(item.id) > -1" style="color: #FFb900;" class="iconfont icon-shoucang"></i>
						<i @click="handleFavorite(item, 1)" v-else class="iconfont icon-shoucang"></i>

						<span style="padding: 0 2px;"></span>

						<span v-if="favoriteList.map(i => i.id).indexOf(item.id) > -1" style="color: #FFb900;">{{ item?.favorite || 0 }}</span>
						<span v-else>{{ item?.favorite || 0 }}</span>
					</div>
				</div>
			</div>
		</div>
		<transition name="fade" mode="out-in">
			<filepreview ref="filepreviewRef" />
		</transition>
	</div>
</template>

<script>
import {addSmallSuffix} from "@/util";
import vm from '../util/eventUtil'
import {mapGetters} from "vuex";
import {getMediaType} from "@/util";
import WaterfallVideoItem from "@/components/WaterfallVideoItem";
import storage from "@/util/storage";
import filepreview from "@/view/filepreview";
export default {
	name: "water-fall3",
	components: {WaterfallVideoItem, filepreview},
	data () {
		return {
			list:[],
			column: 4,
			gutter: 10,
			columnWidth: 400,
			positionTop: [0, 0, 0],
			page: 1,
			pageSize: 30,
			containerDiv: null,
		}
	},
	computed: {
		...mapGetters(['likeList', 'favoriteList'])
	},
	mounted() {
		// window.addEventListener('resize', this.calcRows)
		this.containerDiv = document.querySelector('#waterfall3-masonry')
		this.calcRows()
		this.getData()

		vm.$on('scroll-bottom', this.loadmore)

		vm.$on('window-resize', () => {
			this.setPosition()
		})
	},
	beforeDestroy() {
		// window.removeEventListener('resize', this.calcRows)
	},
	methods: {
		addSmallSuffix,
		getMediaType,
		calcRows () {
			// const masonry = document.querySelector('#waterfall3-masonry')
			let containerWidth = this.containerDiv.clientWidth
			/**
			 *	> 1600 4列  1200-1600 3列  小于1200 2列
			 * **/
			if (containerWidth >= 1500) {
				this.column = 5
				this.columnWidth = (containerWidth - this.gutter * (this.column - 1)) / this.column
				this.positionTop = [0, 0, 0, 0, 0]
			} else if (containerWidth >= 1200) {
				this.column = 4
				this.columnWidth = (containerWidth - this.gutter * (this.column - 1)) / this.column
				this.positionTop = [0, 0, 0, 0]
			} else if (containerWidth > 900){
				this.column = 3
				this.columnWidth = (containerWidth - this.gutter * (this.column - 1)) / this.column
				this.positionTop = [0, 0, 0]
			} else {
				this.column = 2
				this.columnWidth = (containerWidth - this.gutter * (this.column - 1)) / this.column
				this.positionTop = [0, 0]
			}
		},

		setPosition () {
			const items = this.containerDiv.querySelectorAll('.item')

			this.calcRows()
			for (let i = 0; i < items.length; i++) {
				let imgDiv = items[i]

				let width = 0
				let height = 0

				// 首先判断file信息里有没有宽高数据，没有的话获取clientWidth
				let file_id = imgDiv.getAttribute('file_id') * 1
				let itemJson = this.list.find(i => i.id === file_id)
				if (itemJson.file_width !== 0 && itemJson.file_height !== 0) {
					width = itemJson.file_width
					height = itemJson.file_height
				} else {
					let img = imgDiv.querySelector('img')
					// 图片item的实际宽高
					width = img.clientWidth
					height = img.clientHeight
				}

				if (width === 0) {
					// 加载失败的情况
					imgDiv.setAttribute('style', 'height: 0px;')
					continue
				}
				// 图片的布局高度（布局宽度 = columnWidth）
				let styleHeight = height * ( this.columnWidth / width )
				 // 获取当前最短的一列索引
				let minIndex = this.findMinIndex(this.positionTop)
				// 计算定位
				let left = this.columnWidth * minIndex + this.gutter * minIndex
				let top = this.positionTop[minIndex] + this.gutter
				// 存储列高度的数组更新高度
				this.positionTop[minIndex] = top + styleHeight

				// 设置图片定位坐标
				imgDiv.setAttribute('style', `left:${left}px;top:${top}px;width:${this.columnWidth}px;height:${styleHeight}px;`)

			}
			// 设置父元素高度 = 最长列的高度
			this.containerDiv.setAttribute('style', `height:${this.findMax(this.positionTop)}px;`)

		},
		// 获取最短的一列索引
		findMinIndex(arr) {
			let minIndex = 0;
			for (let i = 1; i < arr.length; i++) {
				if (arr[i] < arr[minIndex]) {
					minIndex = i;
				}
			}
			return minIndex;
		},
		// 获取最高的一列的高度， 父元素设置为此高度
		findMax(arr) {
			let max = arr[0];
			for (let i = 1; i < arr.length; i++) {
				if (arr[i] > max) {
					max = arr[i];
				}
			}
			return max;
		},
		setImageOnload () {
			const imgs = this.containerDiv.querySelectorAll('.item')
			for (let i = 0; i < imgs.length; i++) {

				// 首先判断file信息里有没有宽高数据，有的话，不需要添加事件
				let file_id = imgs[i].getAttribute('file_id') * 1
				let itemJson = this.list.find(i => i.id === file_id)
				if (itemJson.file_width !== 0 && itemJson.file_height !== 0) {
					continue
				}

				let img = imgs[i].querySelector('img')
				img.onload = () => {
					setTimeout(() => {this.setPosition()}, 200)
				}
			}
		},

		handleHref (url) {
			if (url.startsWith('http')) {
				return url
			} else {
				return  'http://wanx.pro' + url
			}
		},

		getData () {
			let url = `/file/art/list?page=${this.page}&limit=${this.pageSize}`
			if (this.category) {
				url += `&category=${this.category}`
			}
			this.$http.get(url)
				.then(res => {
					if (res.code === 200) {
						let handleData = res.data.data.map(item => {
							let file_width = 0, file_height = 0, coverUrl = ''
							if (item.ext && typeof item.ext === 'string') {
								let ext = JSON.parse(item.ext)
								file_width = ext.width
								file_height = ext.height
								coverUrl = ext.normal
							} else {
								// 老数据的问题，有的type没更新
								if (item.type === 'video' || getMediaType(item.href) === 'video') {
									// 视频老数据，先默认个400*300
									file_width = 400
									file_height = 300
								} else {
									//
								}
							}

							return {
								...item,
								href: this.handleHref(item.href),
								file_width,
								file_height,
								coverUrl
							}
						})
						if (handleData.length > 0 && this.list.findIndex(i => i.id === handleData[0].id) === -1) {
							this.list = this.list.concat(handleData)
						}
						this.total = res.data.count
						this.$nextTick(() => {
							this.setImageOnload()
						})
					}
				})
		},
		handleLike (file, number = 0) {
			this.$http.post('/file/sms/like', {file_id: file.id})
				.then(res => {
					if (res.code === 200) {
						this.$store.dispatch('getLikeList')
						for (let i = 0; i < this.list.length; i++) {
							if (this.list[i].id === file.id) {
								this.$set(this.list[i], 'like', this.list[i].like + number)
								break
							}
						}

					}
				})
		},
		handleFavorite (file, number) {
			this.$http.post('/file/sms/favorite', {file_id: file.id})
				.then(res => {
					if (res.code === 200) {
						this.$store.dispatch('getFavoriteList')
						for (let i = 0; i < this.list.length; i++) {
							if (this.list[i].id === file.id) {
								this.$set(this.list[i], 'favorite', this.list[i].favorite + number)
								break
							}
						}
					}
				})
		},
		loadmore () {
			console.log('loadmore')
			if (this.total <= this.list.length) {
				return
			}
			this.page++
			this.getData()
		},
		gotoPreview (file) {
			if (getMediaType(file.name) !== 'image') {
				return
			}
			let list = this.list.filter(i => getMediaType(i.name) === 'image')
			this.$refs.filepreviewRef.show(list, file.id)
		}
	}
}
</script>

<style scoped lang="less">

.waterfall3-masonry {
	width: 100%;
	position: relative;
	.item {
		position: absolute;
		overflow: hidden;

		 img {
			width: 100%;
			height: auto;
			 min-height: 80%;
			cursor: pointer;
		}

		.info {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 26px;
			display: flex;
			justify-content: space-between;


			.info-left {
				display: flex;
				align-items: center;
				.avatar {
					height: 26px;
					width: 26px;
					border-radius: 13px;
					background: #A1A3A9;
				}
				.username {
					color: #fff;
					font-size: 13px;
					margin-left: 10px;
				}
			}
			.info-right {
				background: rgba(0,0,0,0.2);
				display: flex;
				align-items: center;
				color: #fff;
				font-size: 13px;
				padding: 0 20px;
				.good,.collect {
					cursor: pointer;
				}
				.collect {
					margin-left: 10px;
				}
			}
		}
	}
	.item:hover {
		box-shadow: 0 0 6px 0 rgba(0, 0, 0, .2);
	}
}

</style>
