<template>
	<waterfall
		ref="waterfall"
		:col="3" :data="list"
		:gutterWidth="20"
		@loadmore="loadmore"
		@scroll="scroll" :lazyDistance="100">
		<template>
			<div class="waterfall-cell-item" v-for="(item,index) in list">

				<img
					:src="addSmallSuffix(item.href, 'normal')" :alt="item.alt"
					:title="item.title"
				/>
<!--				<el-image :src="item.href" lazy />-->
				<div class="info">
					<div class="info-left">
						<!--					<div class="avatar"> </div>-->
						<!--					<div class="username">小花椒 </div>-->
					</div>
					<div class="info-right">
						<div class="good" >
							<i @click="handleLike(item, -1)" v-if="likeList.map(i => i.id).indexOf(item.id) > -1" style="color: #FFb900;" class="iconfont icon-dianzan"></i>
							<i @click="handleLike(item, 1)" v-else class="iconfont icon-dianzan"></i>
							{{ item?.like || 0 }}</div>
						<div class="collect" >
							<i @click="handleFavorite(item, -1)" v-if="favoriteList.map(i => i.id).indexOf(item.id) > -1" style="color: #FFb900;" class="iconfont icon-shoucang"></i>
							<i @click="handleFavorite(item, 1)" v-else class="iconfont icon-shoucang"></i>
							{{ item?.favorite || 0 }}
						</div>
					</div>
				</div>
			</div>
		</template>
	</waterfall>
</template>

<script>

import {mapGetters} from "vuex";
import vm from "@/util/eventUtil";
import {addSmallSuffix} from "@/util";

export default {
	name: 'water-fall2',
	data() {
		return {
			list: [],
			page: 1,
			pageSize: 30,
			total: 0,
			category: '',
			waterfallOptions: {
				columnWidth: 300
			}
		}
	},
	computed: {
		...mapGetters(['likeList', 'favoriteList'])
	},
	mounted() {
		this.getData()
		// window.addEventListener('resize', this.handleResize);
		// vm.$on('scroll-bottom', this.loadmore)
	},
	beforeDestroy() {
		// window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		addSmallSuffix,
		handleResize() {
			this.$waterfall.forceUpdate()   //forceUpdate
		},
		// 分类筛选
		filter (category = '') {
			if (category === this.category) {
				return
			}
			this.page = 1
			this.category = category || ''
			this.list = []
			this.getData()
		},
		getData () {
			let url = `/file/art/list?page=${this.page}&limit=${this.pageSize}`
			if (this.category) {
				url += `&category=${this.category}`
			}
			this.$http.get(url)
				.then(res => {
					if (res.code === 200) {
						this.list = this.list.concat(res.data.data)
						this.total = res.data.count
					}
				})
		},
		loadmore () {
			console.log('=== loadmore ===')
			if (this.total <= this.list.length) {
				return
			}
			this.page++
			this.getData()
		},
		scroll () {
		},
		// 点赞
		handleLike (file, number = 0) {
			this.$http.post('/file/sms/like', {file_id: file.id})
				.then(res => {
					if (res.code === 200) {
						this.$store.dispatch('getLikeList')
						for (let i = 0; i < this.list.length; i++) {
							if (this.list[i].id === file.id) {
								this.$set(this.list[i], 'like', this.list[i].like + number)
								break
							}
						}

					}
				})
		},
		handleFavorite (file, number) {
			this.$http.post('/file/sms/favorite', {file_id: file.id})
				.then(res => {
					if (res.code === 200) {
						this.$store.dispatch('getFavoriteList')
						for (let i = 0; i < this.list.length; i++) {
							if (this.list[i].id === file.id) {
								this.$set(this.list[i], 'favorite', this.list[i].favorite + number)
								break
							}
						}
					}
				})
		},
	}
}

</script>

<style  lang="less">
.waterfall-cell-item {
	img {
		width: 100%;
		height: auto!important;
	}
}


.waterfall-cell-item {
	margin-bottom: 5px;
	overflow: hidden;
	position: relative;
	border-radius: 8px;
	.info {
		position: absolute;
		left: 20px;
		right: 20px;
		bottom: 10px;
		height: 26px;
		display: flex;
		justify-content: space-between;

		.info-left {
			display: flex;
			align-items: center;
			.avatar {
				height: 26px;
				width: 26px;
				border-radius: 13px;
				background: #A1A3A9;
			}
			.username {
				color: #fff;
				font-size: 13px;
				margin-left: 10px;
			}
		}
		.info-right {
			display: flex;
			align-items: center;
			color: #fff;
			font-size: 13px;
			.good,.collect {
				cursor: pointer;
			}
			.collect {
				margin-left: 10px;
			}
		}
	}
}
.waterfall-cell-item .text{
	position: absolute;
	z-index: 9999;
	top: 0px;
	right: 0px;
	left: 0px;
	text-align: center;
	background-color: rgba(30, 29, 29, 0.5); /* 设置背景颜色为白色，透明度为0.5 */
	color: aliceblue;
	transition: 0.3s;
	transform: translateY(-100px);
}
.waterfall-cell-item .img{
	transition: 0.8s;
}
.waterfall-cell-item .img:hover {
	transform: scale(1.2);
}
.waterfall-cell-item:hover .text{
	transform: translateY(0px);
}
</style>
