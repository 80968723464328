<template>
	<div style="padding: 20px;">
		<div class="action" id="myfile-action">
<!--			<el-button type="primary" size="small" class="upload-btn">-->
<!--				上传-->
<!--				<input type="file" @change="handleUpload" accept="image/*">-->
<!--			</el-button>-->
			<div></div>
			<div>
				<el-select v-model="appId" placeholder="请选择模型" size="small" @change="handleAppChange" style="margin-right: 20px;">
					<el-option
						v-for="item in usedApp"
						:key="item.id"
						:label="item.name"
						:value="item.id">
					</el-option>
				</el-select>
<!--				<el-radio-group v-model="listType" size="small"  v-if="mode === 'view'">-->
<!--					<el-radio-button label="2"><i class="el-icon-menu"></i></el-radio-button>-->
<!--					<el-radio-button label="1"><i class="el-icon-s-unfold"></i></el-radio-button>-->
<!--				</el-radio-group>-->
			</div>
		</div>
		<!--		<div class="current-directory">-->
		<!--			<span style="color: #409eff;cursor: pointer;" v-show="directoryPath.length > 1">返回上一级丨</span>-->
		<!--			<div class="directory-item" v-for="(item, ind) in directoryPath">-->
		<!--				<i class="el-icon-arrow-right" v-if="ind > 0"></i>-->
		<!--				<span v-if="ind === directoryPath.length - 1">{{ item.text }}</span>-->
		<!--				<span v-else @click="handleRedirect(item)">{{ item.text }}</span>-->
		<!--			</div>-->
		<!--		</div>-->
		<el-table
			v-if="listType === '1'"
			:data="fileList"
			stripe
			size="small"
			style="width: 100%;height:100%;">
			<el-table-column
				prop="name"
				label="名称"
				min-width="100"
			>
				<template slot-scope="{ row }">
				<span v-if="row.type === 'directory'" class="directory" @click="handleDirectory(row)"> <i
					class="el-icon-folder-opened"></i> {{ row.name }}</span>
					<span v-else>{{ row.name }}</span>
				</template>
			</el-table-column>
			<el-table-column
				prop="size"
				label="大小"
				width="120">
				<template slot-scope="{ row }">
					{{ row.type === 'directory' ? '' : bytesToSize(row.size) }}
				</template>
			</el-table-column>
			<el-table-column
				prop="createTime"
				width="120"
				label="创建时间">
			</el-table-column>
			<el-table-column
				prop="action"
				width="180"
				label="操作">
				<template slot-scope="{ row }">
					<el-button size="small" v-show="row.type !== 'directory' && mode === 'view'" type="text"
							   @click="handleDownloadFile(row)">下载
					</el-button>
					<el-button size="small" v-show="row.type !== 'directory' && mode === 'dialog'" type="text"
							   @click="handleSelect(row)">选择
					</el-button>
					<el-button  size="small"  v-show="row.type !== 'directory' && mode === 'view'" type="text"
							   @click="$refs.fileUpdateRef.show(row)">编辑
					</el-button>
					<el-button size="small" v-show="row.type !== 'directory' && mode === 'view'" type="text"
							   @click="gotoCreate">创作
					</el-button>
					<!--					<el-button v-show="row.type !== 'directory'" type="text" @click="handleDeleteFile(row)">删除</el-button>-->
				</template>
			</el-table-column>
		</el-table>


		<div class="file-list" v-if="listType === '2'">
			<div
				:class="{'file-item': true, 'checked': checkedList.indexOf(item.id) > -1}"
				v-for="item in fileList"
				@click="handleCheckItem(item)"
				:key="item.id">
				<div class="header">
					<span>
						<el-checkbox  @change="handleCheck($event, item)"/>
					</span>
					<el-dropdown trigger="click" size="small" @command="command => handleFileAction(command, item)">
						  <span class="el-dropdown-link">
							<i class="el-icon-more"></i>
						  </span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="download">下载</el-dropdown-item>
							<el-dropdown-item command="update">编辑</el-dropdown-item>
							<el-dropdown-item command="detail">创作</el-dropdown-item>
							<!--							<el-dropdown-item command="delete">删除</el-dropdown-item>-->
						</el-dropdown-menu>
					</el-dropdown>
				</div>
				<div class="header2"></div>
				<div class="content">
					<img v-if="isImage(item.href)" :src="addSmallSuffix(item.href)" alt="">
					<xg-player v-if="item.mediaType === 'video'" :src="item.href" :options="{height: '100%'}"/>
				</div>
				<div class="name ellipsis" :title="item.name">{{ item.name }}</div>
				<div class="size">{{ bytesToSize(item.size) }}</div>
			</div>
		</div>
		<el-pagination
			background
			:page-size="pageSize"
			@current-change="handleCurrentChange"
			layout="prev, pager, next"
			:total="total">
		</el-pagination>

		<FileUpdate ref="fileUpdateRef"  @change="getData"/>
	</div>


</template>


<script>
import {getFileList} from "@/util/filejson";
import {bytesToSize, getMediaType, isImage} from "@/util";
import {uploadFile, getFileCategory} from "@/util/request";
import FileUpdate from "@/components/FileUpdate";
import {addSmallSuffix} from "@/util";
import vm from "@/util/eventUtil";

export default {
	name: "my-file",
	components: {FileUpdate},
	props: {
		mode: {
			type: String,
			default: 'view'
		}
	},
	data() {
		return {
			fileList: [],
			currentDirectory: '/',
			listType: '2',
			prefix: 'http://172.38.40.9:35000',
			fileNameWidth: 500,
			appId: '',
			usedApp: [],
			page: 1,
			pageSize: 30,
			total: 0,
			categoryList: [],
			checkedList: []
		}
	},
	computed: {
		directoryPath() {
			const dirs = this.currentDirectory.split('/')
			let paths = dirs.map((i, inde) => {
				let parentPath = inde === 0 ? '' : dirs.slice(0, inde).join('/')
				return {
					text: inde === 0 ? '全部文件' : i,
					parentPath,
					path: inde === 0 ? '/' : parentPath + '/' + i,
				}
			})
			if (!paths[paths.length - 1]?.text) {
				return paths.slice(0, paths.length - 1)
			}
			return paths
		}
	},
	mounted() {
		this.currentDirectory = '/'

		this.getAppUsed()

		getFileCategory().then(res => {
			if (res.code === 200) {
				this.categoryList = res.data.data
			}
		})
		vm.$on('scroll-bottom', this.handleScrollBottom)
	},
	methods: {
		addSmallSuffix,
		bytesToSize,
		isImage,
		handleCurrentChange (page) {
			this.page = page
			this.getData()
		},
		handleScrollBottom () {},

		handleAppChange (appId) {
			this.appId = appId
			this.page = 1
			this.getData()
		},
		getAppUsed () {
			this.$http.get('/app/used')
			.then(res => {
				if (res.code === 200) {
					this.usedApp = res.data.data
					if (this.usedApp.length > 0) {
						if (!this.appId) {
							this.appId = this.usedApp[0].id
						}
						this.getData()
					} else {

					}
				}
			})
		},
		getData() {
			// getFileList(this.currentDirectory).then(data => {
			// 	this.fileList = data
			// })
			this.$http.get(`/file/list?page=${this.page}&limit=${this.pageSize}&source=${this.appId}`).then(res => {
				if (res.code === 200) {
					this.fileList = res.data.data.map(item => {
						return {
							...item,
							mediaType: getMediaType(item.href)
						}
					})
					this.total = res.data.count
				}
			})
		},
		handleDirectory({path}) {
			this.currentDirectory = path
			this.getData()
		},
		handleRedirect(item) {
			this.currentDirectory = item.path
			this.getData()
		},
		handleSelect(row) {
			this.$emit('select', row.href, row.id)

		},
		handleFileAction(command, file) {
			switch (command) {
				case 'download':
					this.downloadFile(file)
					break
				case 'update':
					// this.handleUpdateFile(file)
					this.$refs.fileUpdateRef.show(file)
					break
				case 'detail':
					this.gotoCreate(file)
					break
			}
		},

		gotoCreate (file) {
			let url = this.$router.resolve({
				name: 'aicreate',
				query: {
					id: file.source,
					file: file.id
				}
			})
			window.open(url.href, '_blank')
		},

		handleDownloadFile(file) {
			window.open(file.href, '_blank')
		},
		downloadFile(file) {
			const link = document.createElement('a');
			link.href = file.href;
			link.download = ''; // 设置下载文件的名称，如果留空则使用URL中的文件名
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
		handleDeleteFile(file) {

		},
		handleUpload(event) {
			const file = event.target.files[0];
			uploadFile(file)
				.then(res => {
					console.log(res)
				})
				.finally(() => {
					this.getData()
				})
		},
		handleCheck (checked, item) {
			if (checked) {
				this.checkedList.push(item.id)
			} else {
				const index = this.checkedList.indexOf(item.id)

				(index > -1) && (this.checkedList.splice(index, 1))
			}
		},
		handleCheckItem (row) {
			if (this.mode === 'dialog') {
				this.$emit('select', row.href, row.id)
			}
		}
	}
}
</script>

<style scoped lang="less">

.action {
	display: flex;
	justify-content: space-between;
	padding: 0 20px;
}

.directory {
	color: #409eff;
	cursor: pointer;
}

.current-directory {
	display: flex;
	flex-wrap: nowrap;
	border-bottom: solid 1px #eee;
	padding: 20px 0;
}

.directory-item {
	font-size: 14px;

	> span {
		cursor: pointer;
		color: #409eff;
	}
}

.directory-item:last-child {
	> span {
		cursor: auto;
		color: #666;
	}
}

.file-list {
	display: flex;
	justify-content: left;
	padding: 10px;
	flex-wrap: wrap;

	.file-item {
		width: 160px;
		height: 234px;
		padding: 0 10px;
		//background: #fff;
		border-radius: 5px;
		cursor: pointer;
		margin: 0 10px 10px 0;

		.header {
			display: none;
			height: 26px;
			justify-content: space-between;
		}

		.header2 {
			height: 26px;
		}

		.content {
			height: 128px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;

			> img {
				max-width: 100%;
				max-height: 100%;
			}
		}

		.name, .size {
			opacity: 1;
			font-size: 12px;
			color: #666;
			height: 18px;
			line-height: 18px;
			margin-top: 8px;
			text-align: center;
		}
	}


	.file-item:hover, .file-item.checked {
		background: #eee;

		.header {
			display: flex;
		}

		.header2 {
			display: none;
		}

		.name, .size {
			opacity: 1;
		}
	}
}

.upload-btn {
	position: relative;
	cursor: pointer;

	input[type=file] {
		position: absolute;
		opacity: 0;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		cursor: pointer;
	}
}
</style>
