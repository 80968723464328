<template>
	<div class="component out-item" @click="$emit('checked', outItem)">
<!--		<div class="download-btn" @click="downloadFile(outItem.href)">-->
<!--			<i class="el-icon-download"></i>-->
<!--		</div>-->
		<img v-if="getMediaType(outItem.href) === 'image'" :src="outItem.href" alt=""/>
		<video v-if="getMediaType(outItem.href) === 'video'" :src="outItem.href" alt=""/>
	</div>
</template>

<script>
import {getMediaType} from "@/util";

export default {
	name: "out-item",
	props: {
		outItem: {
			type: Object,
			default: () => ({}),
		}
	},
	data () {
		return {

		}
	},
	methods: {
		getMediaType,
		downloadFile(url) {
			const link = document.createElement('a');
			link.href = url;
			link.download = ''; // 设置下载文件的名称，如果留空则使用URL中的文件名
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
		downloadByUrl(url, fileName) {
			if (!fileName) {
				// fileName = this.outImage.split('/')?
			}
			if (!url) {
				return
			}

			// window.open(url)

			fetch(url)
				.then(response => response.blob())
				.then(blob => {
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.href = url;
					a.download = fileName || 'unknown';
					a.style.display = 'none';
					a.target = '_blank'
					document.body.appendChild(a);
					a.click();
					window.URL.revokeObjectURL(url);
					document.body.removeChild(a);
				})
				.catch(e => console.error(e));
		},
	}
}
</script>

<style scoped lang="less">

.out-item {
	width: 50px;
	height: 50px;
	cursor: pointer;
	border-radius: 10px;
	z-index: 2;
	position: relative;
	display: flex;
	justify-content: center;
	margin:  20px 20px 0;
	border: 1px solid #eee;

	.download-btn {
		position: absolute;
		top: 6px;
		right: 6px;
		width: 20px;
		height: 20px;
		border-radius: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(255, 102, 30, .4);
		cursor: pointer;
		z-index: 14;
		color: #fff;
	}

	> img {
		max-width: 100%;
		max-height: 100%;
		z-index: 3;
	}
}
</style>
