<template>
	<div class="page page-app">
		<fm-making-form
			ref="makingform"
			style="height:800px;display: block;"
			preview
			:basic-fields="basicFields"
			:advance-fields="advanceFields"
			:layout-fields="[]">
			<!-- 头部内容自定义，添加提交按钮 -->
			<template slot="action">
				<el-button type="primary" @click="handleSubmit">提交</el-button>
			</template>
		</fm-making-form>
	</div>
</template>

<script>
export default {
	name: "app-manage",
	data () {
		return {
			basicFields: ['textarea', 'number', 'radio', 'checkbox', 'select', 'time', 'date', 'rate', 'slider', ],
			advanceFields: ['fileupload', 'imgupload', 'editor', 'blank', 'component']
		}
	},
	methods: {
		handleSubmit () {

		}
	}
}
</script>

<style scoped>

</style>
