export function bytesToSize(bytes, fixed = 2) {
    if (bytes < 1) return bytes + ' B';

    if (!bytes) {
        return ' '
    }

    let k = 1024;

    let sizes = ['B','KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    let i = Math.floor(Math.log(bytes) / Math.log(k));

    let num = bytes / Math.pow(k, i);
    return num.toFixed(fixed) + ' ' + sizes[i];

    //return (bytes / Math.pow(k, i)) + ' ' + sizes[i];
    //toPrecision(3) 后面保留一位小数，如1.0GB //return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
}

export function isImage(filename = '') {
    // const fileExtension = filename.split('.').pop().toLowerCase();
    // const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
    // return imageExtensions.includes(fileExtension);
    return getMediaType(filename) === 'image'
}

export function addSmallSuffix(url = '', suffix = 'small') {
    const lastDotIndex = url.lastIndexOf('.');
    if (lastDotIndex === -1) {
        return url + '_' + suffix;
    }
    return url.slice(0, lastDotIndex) + '_' + suffix + url.slice(lastDotIndex);
}

export function getMediaType(href) {
    const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv'];
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];

    const urlParts = href.split('.');
    const fileExtension = urlParts[urlParts.length - 1].toLowerCase();

    if (videoExtensions.includes(fileExtension)) {
        return 'video';
    } else if (imageExtensions.includes(fileExtension)) {
        return 'image';
    } else {
        return 'other';
    }
}


