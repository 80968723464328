<template>
	<div class="c-ai-input">
		<el-form ref="form" label-width="80px" :model="form" :label-position="'top'" size="small">
			<el-row :gutter="20" class="row-container">
				<el-col class="item-col" v-for="input in requiredList" :span="layout === 'horizontal' ? 24 : 12" :key="input.field">
					<el-form-item  :prop="input.field" >
						<div slot="label">
							<div class="label-text"> {{ input.label }}
								<span v-show="input.type === 'slider'" style="font-size: 12px; color: #999;"> {{ form[input.field] }} </span>
							</div>
							<p class="label-desc" v-if="!!input.desc">{{ input.desc }}</p>
						</div>
						<el-input type="textarea" :rows="4" @keydown.stop v-model="form[input.field]" v-if="input.type === 'text'" >
						</el-input>
						<el-input-number
							v-if="input.type === 'number' || input.type === 'float' "
							:controls="false"
							@keydown.stop v-model="form[input.field]"
							:precision="input.precision || 0"
						/>
						<el-slider
							v-if="input.type === 'slider'"
							size="small"
							:step="input.step"
							:min="input.min"
							:max="input.max"
							v-model="form[input.field]"
							>
						</el-slider>
						<el-select
							v-if="input.type === 'select'"
							v-model="form[input.field]"
							filterable
							:allow-create="input.edit"
							default-first-option
							placeholder="请选择" size="small">
							<el-option
								v-for="item in input.select"
								:key="item.value"
								:label="item.key"
								:value="item.value">
							</el-option>
						</el-select>
						<el-checkbox-group v-model="form[input.field]" v-if="input.type === 'checkbox'" >
							<el-checkbox :label="check.value" v-for="check in input.select" :key="check.value">{{check.key}}</el-checkbox>
						</el-checkbox-group>
						<el-radio-group v-model="form[input.field]" v-if="input.type === 'radio'"  size="small">
							<el-radio :label="radio.value" v-for="radio in input.select"  :key="radio.value">{{ radio.key }}</el-radio>
						</el-radio-group>
						<file-upload  v-if="input.type==='image'"  :defaultUrl="input.defaultUrl" :example="input.example"  @change="(id, url) => {handleFileChange(input.field, id)}" />
						<VideoUpload v-if="input.type === 'video'" :defaultUrl="input.defaultUrl"  :example="input.example"  @change="(url, id) => {handleFileChange(input.field, id)}"/>
						<div class="line"></div>
					</el-form-item>
				</el-col>
			</el-row>

			<div class="more-set" v-show="moreList.length > 0"  @click="showMoreSet = !showMoreSet">
				<span >更多设置</span>
				<i class="el-icon-caret-top" v-show="!showMoreSet"></i>
				<i class="el-icon-caret-bottom" v-show="showMoreSet"></i>
			</div>

			<el-row :gutter="20" v-show="showMoreSet && moreList.length > 0" class="row-container">
				<el-col class="item-col" v-for="input in moreList" :span="layout === 'horizontal' ? 24 : 12" :key="input.field">
					<el-form-item :prop="input.field" >
						<div slot="label">
							<div class="label-text"> {{ input.label }}
								<span v-show="input.type === 'slider'" style="font-size: 12px; color: #999;"> {{ form[input.field] }} </span>
							</div>
							<p class="label-desc" v-if="!!input.desc">{{ input.desc }}</p>
						</div>
						<el-input @keydown.stop v-model="form[input.field]" v-if="input.type === 'text'" >
						</el-input>
						<el-input-number
							v-if="input.type === 'number' || input.type === 'float' "
							:controls="false"
							@keydown.stop v-model="form[input.field]"
							:precision="input.precision || 0"
						/>
						<el-slider
							v-if="input.type === 'slider'"
							size="small"
							:step="input.step"
							:min="input.min"
							:max="input.max"
							v-model="form[input.field]"
						>
						</el-slider>
						<el-select   v-if="input.type === 'select'" v-model="form[input.field]" placeholder="请选择" size="small">
							<el-option
								v-for="item in input.select"
								:key="item.value"
								:label="item.key"
								:value="item.value">
							</el-option>
						</el-select>
						<el-checkbox-group v-model="form[input.field]" v-if="input.type === 'checkbox'" >
							<el-checkbox :label="check.label" v-for="check in input.select" :key="check.value">{{check.key}}</el-checkbox>
						</el-checkbox-group>
						<el-radio-group v-model="form[input.field]" v-if="input.type === 'radio'"  size="small">
							<el-radio :label="radio[1]" v-for="radio in input.select"  :key="radio.value">{{ radio.key }}</el-radio>
						</el-radio-group>
						<file-upload  v-if="input.type==='image'"  :defaultUrl="input.defaultUrl" :example="input.example"  @change="(id, url) => {handleFileChange(input.field, id)}" />
						<VideoUpload v-if="input.type === 'video'" :defaultUrl="input.defaultUrl"  :example="input.example"  @change="(url, id) => {handleFileChange(input.field, id)}"/>
						<div class="line"></div>
					</el-form-item>
				</el-col>
			</el-row>

<!--			<el-button type="primary" class="submit-btn"  @click="onSubmit" size="small">提交</el-button>-->

		</el-form>
	</div>
</template>

<script>
/**
 * input textarea number
 * Slider Checkbox Radio
 * image
 * **/

import FileUpload from "@/components/FileUpload";
import VideoUpload from "@/components/VideoUpload";

export default {
	name: "user-manage",
	components: { FileUpload, VideoUpload },
	props: {
		inputList: {
			type: Array,
			default: () => ([])
		},
		layout: {
			type: String,
			default: 'horizontal'
		}
	},
	created() {

	},
	mounted () {
		this.inputList.map(i => {
			this.$set(this.form, i.field, (typeof i.value === 'boolean' ? i.value : (i.value || '')))
			// this.form[i.field] = ''
		})
	},

	computed: {
		requiredList () {
			// 必选项：没有默认值的
			return this.inputList.filter(i => (i.type!=='userid' && i.group !== 'pro'))
			// return this.inputList.slice(0,6)
		},
		moreList () {
			// 非必选项，有默认值的
			return this.inputList.filter(i => (i.type!=='userid' && i.group === 'pro')) || []
		}
	},

	data () {
		return {
			form: {

			},
			rules: {

			},
			showMoreSet: false,
		}
	},
	methods: {

		check(val){
			this.$forceUpdate(); //强制刷新, 解决radio bug
		},

		setDefaultValue (values) {
			values.forEach((item, index) => {
				const {field, defaultValue, defaultUrl} = item
				this.form[field] = defaultValue
				if (defaultUrl) {
					this.$set(this.inputList[index], 'defaultUrl', defaultUrl)
				}
			})
			this.$forceUpdate()
		},

		onSubmit () {
			let keys = Object.keys(this.form), hasValue = 0

			for (let i = 0; i < keys.length; i++) {
				if (this.form[keys[i]]) {
					hasValue++
					break
				}
			}
			if (hasValue === 0) {
				this.$message.warning('没有任何输入信息，请检查')
				return
			}

			for (let i = 0; i < this.inputList.length; i++) {
				if (this.inputList[i].field === 'user') {
					continue
				}
				// 检查前五个字段 必填
				if (i < 5) {
					if (!this.form[this.inputList[i].field]) {
						this.$message.warning(`请选择${this.inputList[i].label || ''}`)
						return;
					}
				}
			}

			this.$emit('submit', this.form)
		},
		async handleFileChange (field, id) {
			this.form[field] = id
		}
	}
}
</script>

<style scoped lang="less">
.submit-btn {
	width: 100%;
}
.c-ai-input {
	/*background: #f9fafb;*/
	padding: 20px;
	border-radius: 10px;
	//.item-col {
	//	position: relative;
	//	.tip {
	//		position: absolute;
	//		top: 0px;
	//		left: 0px;
	//		width: 70px;
	//		height: 26px;
	//		border-right: 1px solid #ddd;
	//		border-bottom: 1px solid #ddd;
	//		border-radius: 0 0 6px 0;
	//		background: #fff;
	//		z-index: 4;
	//		color: #999;
	//		text-align: center;
	//		line-height: 26px;
	//		font-size: 12px;
	//	}
	//}

}
.line {
	background: #eee;
	height: 1px;
	width: 100%;
	margin-top: 8px;

}

.more-set {
	cursor: pointer;
	margin: 10px 0;

}

.row-container {
	border: 1px solid #ddd;
	background: #f9fafb;
	border-radius: 10px;
}

.label-text{
	color: #666;
	font-size: 14px;
	line-height: 28px;
}

.label-desc {
	color: #999;
	font-size: 12px;
	line-height: 22px;
}
</style>
