<template>
	<div class="header-container">
		<div class="header">
			<div class="left">
				<div class="logo" >
					<div class="logo1" @click.stop="showMenu"></div>
					<div class="logo2" @click="$router.push('/')"></div>
				</div>
				<div class="menu">
					<span
						:key="item.text"
						@click="setMenuChecked(index, item)"
						:class="{'menu-item': true, 'checked': item.path === menuChecked}"
						v-for="(item,index) in menuList">
						{{ item.text }}
					</span>
				</div>
			</div>
			<div class="right">
				<div class="action" >
					<div class="action-item" v-show="isLogin" >
					</div>
					<div class="action-item" v-show="isLogin" ></div>
				</div>
				<div class="user" v-if="isLogin">
					<div class="avatar"></div>
					<div class="username">
						<el-dropdown @command="handleUserAction" size="small">
							<span class="el-dropdown-link">
								{{ userInfo.nickname }}<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item
									v-for="item in userActions"
									:key="item.value"
									:command="item.value">{{ item.text }}
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
				<div class="user-logout" v-else>
					<el-button type="text" @click="$router.push({name: 'login'})">登录</el-button>
				</div>
			</div>

			<transition name="fade" mode="out-in">
				<div v-if="showAside" class="aside-c" @click.stop>
					<Aside/>
				</div>
			</transition>
		</div>
<!--		<CreateTaskList ref="createTaskListRef"/>-->

	</div>
</template>

<script>

import {mapGetters} from 'vuex'
import storage from "@/util/storage";
import Aside from "@/view/layout/Aside";
import {openaiHKRequest} from "@/util/request";
import CreateTaskList from "@/components/CreateTaskList";

export default {
	name: "layout-header",
	components: {Aside, CreateTaskList},
	data() {
		return {
			menuList: [
				{text: '网站首页', path: '/'},
				{text: '工具', path: '/apps'},
				{text: '行业资讯', path: '/industry'},
				{text: '关于我们', path: '/about'},
				{text: '联系我们', path: '/contact'},
				// {text: '价格', path: ''},
				// {text: '联盟', path: ''},
				// {text: '教程', path: ''},
			],
			menuChecked: '',
			userActions: [
				{text: '个人信息', value: 'userinfo'},
				{text: '退出', value: 'exit'},
			]
		}
	},
	mounted() {
		// this.$store.dispatch('getUserInfo')
		console.log(this.$route)
		this.menuChecked = this.$route.path
	},
	computed: {
		...mapGetters(['isLogin', 'userInfo', 'showAside'])
	},
	methods: {
		setMenuChecked(ind = 0, item) {
			if (item.path === '/contact') {
				this.$alert('18522103918', '联系电话', {})
				return
			}
			this.menuChecked = item.path

			if (item.path) {
				this.$router.push(item.path)
			} else {
				this.$message.info('敬请期待')
			}
		},
		handleUserAction(command) {
			if (command === 'userinfo') {
				this.$router.push({name: 'info'})
			} else if (command === 'exit') {
				this.$router.push({name: 'login'})
				this.$store.commit('set_user_info', {})
				storage.remove('token')
			}
		},
		showMenu() {
			this.$store.commit('set_aside_show', !this.showAside)
		},
		// testopenaihk () {
		// 	this.$refs.createTaskListRef.show()
		// },
		// test () {
		// 	this.$http.get('/admin/app/list')
		// }
	}
}
</script>

<style lang="less">
.header-container {
	border-bottom: 1px solid #EAECEF;
	background: #fff;

	.header {
		height: 66px;
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin: 0 auto;
		position: relative;
		overflow: visible;

		.aside-c {
			position: absolute;
			background: #fff;
			top: 67px;
			left: 0;
			width: 300px;
			height: 700px;
			z-index: 5;
			padding: 0 20px;
			box-shadow: 0 0 6px 0 rgba(0, 0, 0, .2);
		}
	}

	.left {
		display: flex;
		flex-direction: row;

		.logo {
			display: flex;
			flex-direction: row;
			align-items: center;
			flex-basis: 300px;
			flex-shrink: 0;
			flex-grow: 0;

			.logo1 {
				width: 42px;
				height: 42px;
				margin-left: 21px;
				border-radius: 21px;
				background: #F1F2F8 url("../../assets/image/logo1.png") no-repeat center center;
				cursor: pointer;
			}

			.logo2 {
				cursor: pointer;
				height: 60px;
				width: 180px;
				margin-left: 15px;
				background: url("../../assets/image/wanxianglogo2.png") no-repeat center center;
				background-size: 180px 60px;
			}
		}

		.menu {
			display: flex;
			flex-wrap: nowrap;
			justify-content: left;
			align-items: center;

			.menu-item {
				font-size: 16px;
				margin: 0 18px;
				height: 28px;
				line-height: 22px;
				min-width: 30px;
				white-space: nowrap;
				padding: 3px 12px;
				border-radius: 6px;
				cursor: pointer;
				color: #A1A3A9;
			}

			.menu-item.checked, .menu-item:hover {
				background: #F4F5F9;
				color: #FF661E;
			}
		}

	}

	.right {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-right: 24px;

		.action {
			display: flex;

			&-item {
				width: 32px;
				height: 32px;
				margin: 0 9px;
				background: #A1A3A9;
			}

			&-item:nth-child(1) {
				background: url("../../assets/image/vipicon.png") no-repeat center center;
			}

			&-item:nth-child(2) {
				background: url("../../assets/image/messageicon.png") no-repeat center center;
			}
		}

		.user {
			display: flex;
			flex-direction: row;
			align-items: center;

			.avatar {
				width: 36px;
				height: 36px;
				border-radius: 18px;
				margin-right: 4px;
				background: url("../../assets/image/avatar.png") no-repeat center center;
			}

			.username {
				cursor: pointer;
			}
		}
	}
}

</style>
