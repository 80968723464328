<template>
	<el-dialog
		:visible.sync="visible"
		title="从我的媒体库中选择"
		append-to-body
		width="1000px"
		:close-on-click-modal="false">
		<MyFile mode="dialog" @select="handleSelect"/>
	</el-dialog>
</template>


<script>
import MyFile from "./MyFile";

export default {
	name: "my-file",
	components: { MyFile },
	data () {
		return {
			visible: false,
			prefix: 'http://172.38.40.9:35000'
		}
	},
	mounted() {

	},
	methods: {
		show () {
			this.visible = true
		},
		handleSelect (href = '', id) {
			if (href&&href.startsWith('http')) {
				this.$emit('select', href, id)
			} else {
				this.$emit('select', this.prefix+href, id)
			}
			this.visible = false
		}
	}
}
</script>

<style scoped lang="less">
</style>
