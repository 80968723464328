<template>
	<div class="upload-container">
		<input v-if="!showCamera" type="file" ref="fileInput" @change="handleFileUpload" style="display: none;" accept="image/*">
		<div v-if="!showCamera"  class="dropzone" @dragover.prevent @dragenter.prevent @drop="handleDrop" @click="openFileDialog">
			<div v-if="uploadedFileUrl" class="preview">
				<img :src="uploadedFileUrl" alt="上传的图片预览">
			</div>
			<div class="upload-info" v-else>
				<i class="el-icon-upload"></i>
				<p>拖拽文件到这里或者点击上传</p>
			</div>
		</div>

		<!--	相机拍照  -->
		<CameraImage  v-if="showCamera" @complete="handleFileFromFile" />
		<div class="upload-type">
			<div class="type-item" @click="showUpload">
				<i class="el-icon-upload"></i>
			</div>
			<div class="type-item" @click="showMyFile">
				<i class="el-icon-folder-opened"></i>
			</div>
			<div  class="type-item" v-show="isHttps()" @click="takePhoto" >
				<i class="el-icon-camera"></i>
			</div>
<!--			<el-button type="text" @click="recordVideo">-->
<!--				录制录像-->
<!--			</el-button>-->
		</div>

		<ImageList  v-show="example.length > 0"  :images="example" @select="selectFromExample"/>


		<!--  从媒体库选取  -->
		<MyFile ref="myFileRef" @select="handleFileFromFile"/>
	</div>
</template>

<script>
import MyFile from "@/components/MyFileDialog";
import Recording from "@/components/Recording";
import CameraImage from "@/components/CameraImage";
import ImageList from "@/components/ImageList";
import {uploadFile} from "@/util/request";
import {getMediaType} from "@/util";

export default {
	components: {MyFile, Recording, CameraImage, ImageList},
	props: {
		example: {
			type: Array,
			default: () => ([])
		},
		defaultUrl: {
			type: String,
			default: ''
		}
	},
	watch: {
		defaultUrl (val) {
			console.log('===image defaultUrl===', val)
			if (val) {
				this.uploadedFileUrl = val
			}
		}
	},
	data() {
		return {
			uploadedFileUrl: '',
			mediaUrl: '',
			type: 'upload', // 获取资源的来源类型， upload:选本地文件 file:我的媒体库 camera：录像 recording拍照
			showCamera: false,
			id: ''
		};
	},
	methods: {
		getMediaType,
		isHttps() {
			return window.location.protocol === 'https:';
		},
		handleUploadFile(file) {
			uploadFile(file).then(res => {
				this.uploadedFileUrl = res.url
				this.id = res.id
				this.emitChange()
			})
		},
		selectFromExample (path) {
			if (path) {
				this.uploadedFileUrl = path
				this.emitChange()
			} else {
				this.$message.warning('未获取到文件')
			}
		},
		handleComplete (file) {
			this.showCamera = false
			if (file) {
				// this.uploadedFileUrl = path
				// this.handleUploadFile(file)
			} else {
				this.$message.warning('未获取到文件')
			}
		},
		emitChange () {
			this.$emit('change', this.id, this.uploadedFileUrl)
		},
		openFileDialog() {
			this.$refs.fileInput.click();
		},
		handleFileUpload(event) {
			const file = event.target.files[0];
			if (file) {
				// this.uploadedFileUrl = URL.createObjectURL(file);
				// this.emitChange()
				this.handleUploadFile(file)
			}
		},
		handleDrop(event) {
			event.preventDefault();
			const files = event.dataTransfer.files;
			if (files.length > 0) {
				const file = files[0];
				this.handleUploadFile(file)

			}
		},
		showMyFile () {
			this.type = 'file'
			this.$refs.myFileRef.show()
			this.showCamera = false
		},
		showUpload () {
			this.uploadedFileUrl = ''
			this.showCamera = false
		},
		takePhoto() {
			this.showCamera = true
		},
		recordVideo() {
			this.type = 'recording'
		},
		completeRecording(path) {
			if (path) {
				this.uploadedFileUrl = path
				this.emitChange()
			}
		},
		// 从媒体库选文件 and 摄像头拍照
		handleFileFromFile (url, id) {
			console.log('---------',url, id)
			if (getMediaType(url) !== 'image') {
				this.$message.warning('文件类型错误，请选择图片')
				return
			}
			this.showCamera = false
			this.uploadedFileUrl = url
			this.id = id
			this.emitChange()
		}
	},
};
</script>

<style scoped lang="less">
.upload-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.dropzone {
	width: 100%;
	height: 200px;
	border: 1px dashed #ccc;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.dropzone:hover {
	border: 1px dashed #409eff;
}

.dropzone i {
	font-size: 48px;
	margin-bottom: 10px;
}

.dropzone p {
	font-size: 16px;
	color: #aaa;
}

.upload-info {
	color: #999;
	flex-direction: column;
	display: flex;
	justify-content: center;align-items: center;
}

.preview {
	max-width: 100%;
	max-height: 100%;

	> img {
		max-width: 100%;
		max-height: 100%;
	}
}
.upload-type {
	display: flex;
	color: #409eff;
	justify-content: space-around;
	font-size: 18px;
	> .type-item {
		margin: 0 8px;
		cursor: pointer;
	}
}

</style>
