<template>
	<div class="component create-task-list">
		<el-drawer
			title="任务列表"
			:visible.sync="drawer"
			:direction="direction"
		>

			<el-table
				:data="taskList"
				stripe
				size="small"
				border
				style="width: 100%;height:100%;"
			>
				<el-table-column
					align="center"
					prop="username"
					label="工具"></el-table-column>
				<el-table-column
					align="center"
					prop="username"
					label="提交时间"></el-table-column>
				<el-table-column
					align="center"
					prop="username"
					label="状态"></el-table-column>
				<el-table-column
					align="center"
					prop="username"
					label="操作">
					<template slot-scope="{ row }">
						<el-button size="small"  type="text"
								   @click="handleViewResult(row)">查看结果
						</el-button>
					</template>
				</el-table-column>
			</el-table>

		</el-drawer>
	</div>
</template>

<script>
export default {
	name: "create-task-list",
	data () {
		return {
			drawer: false,
			direction: 'rtl',
			taskList: []
		}
	},
	methods: {
		show () {
			this.drawer = true
			this.getData()
		},
		handleViewResult () {},
		getData () {

		}
	}
}
</script>

<style scoped>

</style>
