<template>
	<div class="image-list">
		<div class="image-container" ref="imageContainer">
			<video @click="handleSelect(video)" v-for="(video, index) in videos" :key="index" :src="video" alt="图片" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'video-list',
	props: {
		videos: {
			type: Array,
			required: true,
		},
	},
	mounted() {
		this.$nextTick(() => {

		});
	},
	methods: {
		handleSelect(url){
			this.$emit('select', url)
		}
	}
};
</script>

<style scoped>
.image-list {
	width: 100%;
	height: 100px;
	overflow-x: scroll;
	overflow-y: hidden;
}

.image-list::-webkit-scrollbar {
	height: 10px;
}
.image-list::-webkit-scrollbar-track {
	background: #f1f1f1; /* 设置滚动条背景颜色 */
}

.image-list::-webkit-scrollbar-thumb {
	background-color: #aaa; /* 设置滚动条颜色 */
	border-radius: 5px; /* 设置滚动条圆角 */
	width: 2px
}


.image-container {
	display: flex;
	height: 100px;
	column-gap: 5px;
}

.image-container video {
	height: 100px;
	cursor: pointer;
}
</style>
