<template>
	<div class="ai-create">
		<Header/>

		<div class="create-main" v-if="!isIframe">
			<div class="ai-input-container">
				<div class="submit-btn">
					<el-button :loading="submitBtnLoading"  style="width: 240px;" type="primary" size="small" @click="submitCreate">
						提交
					</el-button>
				</div>
				<div class="header" v-show="!!appInfo.name">
					<div class="ainame">{{ appInfo.name }}

						<el-popover
							placement="right"
							width="200"
							trigger="hover"
							:content="appInfo.remark">
							<i slot="reference" style="color:#999;cursor:pointer;" class="el-icon-info"></i>
						</el-popover>
					</div>
					<div style="margin-top: 10px;">
						<span class="good"><i class="iconfont icon-dianzan"></i> 1273</span>
						<span class="collect"><i class="iconfont icon-shoucang"></i> 1273</span>
					</div>

				</div>
				<div class="ai-input">
					<!--					<span class="tip">input</span>-->
					<AiInput ref="aiInputRef" v-if="inputList && inputList.length > 0" :layout="layout"
							 :input-list="inputList" @submit="handleSubmit"/>

					<!--					<span class="screen-btn">-->
					<!--						<i @click="exitFullscreen" v-if="fullscreen" class="iconfont  icon-quxiaoquanping"></i>-->
					<!--						<i @click="openFullscreen" v-else class="iconfont icon-quanping "></i>-->
					<!--					</span>-->
				</div>

			</div>

			<div class="ai-out-c">

				<div
					class="ai-out"
				>
					<span class="tip">生成的图片/视频</span>
					<div class="submit-loading" :style="`right:${rightPosition}`">
						<div class="submit-loading-inner"></div>
					</div>
					<div class="submit-process">
						{{ submitTimeLoading }}s / {{ submitTime }}s
					</div>

					<div class="out-list">
						<span
							v-for="i in outList"
							@click="handleOutView(i)"
							:class="{'out-item-small': true, 'checked': outItemView?.value === i.value}">
							<SmallIcon
								:type="i.type"
								:src="addSmallSuffix(i.value)"
							/>
						</span>

					</div>
					<div class="out-item-view" style="padding: 10px;">
						<BigOutItem v-if="outItemView && outItemView.value" :out-item="outItemView" />
					</div>
				</div>
			</div>

			<div :class="{'ai-history': true, 'show': showHistoryList}">
				<div class="head">
					<span>历史记录</span>
					<span></span>
<!--					<span v-show="maxPageSize > 1" class="history-change" @click="handleHistoryChange">换一批  <i-->
<!--						class="el-icon-refresh"></i> </span>-->
					<!--					<span class="close" @click="showHistoryList = false"> <i class="el-icon-close"></i> </span>-->
				</div>
				<div class="history-pagination">
					<el-pagination
						v-show="historyTotal > historySize"
						:pager-count="5"
						style="width: 264px;"
						background
						layout="pager"
						@current-change="handleHistoryCurrentChange"
						:page-size="historySize"
						:total="historyTotal">
					</el-pagination>
				</div>
				<div class="history-item" v-for="(item,ind) in historyList" :key="item.task_id"
					 @click="getHistoryResult(item)">
					<div class="left">
						<div class="date">{{ moment(item.create_at).utc().format('YYYY-MM-DD HH:mm:ss') }}</div>
						<div class="status " :title="item.name">
							<span v-show="item.status === 0">待处理</span>
							<span v-show="item.status === 1">处理中</span>
							<span v-show="item.status === 2" class="color_success">已完成</span>
							<span v-show="item.status === -1">取消处理</span>
							<span v-show="item.status === -2" class="color_fail">异常</span>
						</div>
					</div>

					<div class="right">
						<div class="list" v-if="item.result" >
							<div class="out-item" :key="item.result.href" >
								<SmallIcon :type="item.result.type" :src="addSmallSuffix(item.result.href)"/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--		<div class="history-btn" @click="showHistory">-->
		<!--			<i style="font-size: 24px;" class="iconfont icon-lishijilu" />-->
		<!--		</div>-->
		<iframe

			v-if="isIframe"
			ref="gradioIframe"
			id="model-content"
			:src="appInfo.url"
			@load="iframeLoad"
		/>
	</div>
</template>

<script>
import AiInput from "@/components/AiInput";
import {mapGetters} from "vuex";
import storage from "@/util/storage";
import moment from 'moment'
import Header from './layout/Header'
import OutItem from "@/components/OutItem";
import BigOutItem from "@/components/BigOutItem";
import {addSmallSuffix} from "@/util";
import SmallIcon from "@/components/SmallIcon";

export default {
	name: "ai-create",
	components: {AiInput, Header, OutItem, BigOutItem, SmallIcon},
	data() {
		return {
			appInfo: {},
			inputList: [],
			outList: [],
			loading: false,
			loading2: false,
			id: '',
			title: '',
			layout: 'horizontal', // 布局方向 vertical 纵向  horizontal 横向
			isIframe: false,
			url: '',
			toolList: [],
			historyPage: 1,
			historySize: 10,
			historyList: [],
			historyTotal: 0,
			showHistoryList: false,
			fileId: '',
			submitTime: 30,
			submitTimeLoading: 0,
			timer: null,
			submitBtnLoading: false,
			outItemView: {}
		}
	},
	computed: {
		...mapGetters(['isLogin', 'fullscreen']),
		rightPosition() {
			const {submitTime, submitTimeLoading} = this

			let ratio = (submitTime - submitTimeLoading) / submitTime * 100

			return ratio < 0 ? 0 : ratio + '%'
		},
		maxPageSize() {
			const {historyTotal, historySize} = this

			return Math.ceil(historyTotal / historySize)
		}
	},
	mounted() {
		// 非iframe 插入
		// appid
		this.id = this.$route.query.id
		this.fileId = this.$route.query.file
		if (!this.id) {
			this.$message.warning('未获取到模型id')
			return
		}

		this.getAppDetail()

		// this.inputList = ai_setup[this.id]?.input
		// this.title =  ai_setup[this.id]?.title
		// this.layout = this.inputList.filter(i => i.type === 'image' || i.type === 'video').length > 1 ? 'vertical' : 'horizontal'

		// iframe插入方式
		// this.toolList = storage.get('gradioapps')
		// const url = this.toolList.find(i => i.id === this.id)?.url
		// if (url) {
		// 	this.url = url + '?__theme=light'
		//
		// 	// this.loading2 = true
		// 	//
		// 	// setTimeout(() => { this.loading2 = false },2000)
		// } else {
		// 	this.$message.warning('未获取到模型url')
		// }
		this.getHistory()
	},
	methods: {
		addSmallSuffix,
		moment,
		handleOutView (outItem) {
			console.log(outItem)
			this.outItemView = outItem
		},
		submitCreate () {
			this.$refs.aiInputRef.onSubmit()
		},
		handleHistoryResult ({image = [], text = [],  video = [], voice = []}) {
			let outList = []
			// result: {image: [], text: [], video: [], voice: []}
			image.forEach(item => {
				outList.push({
					type: 'image',
					value: item
				})
			})
			text.forEach(item => {
				outList.push({
					type: 'text',
					value: item
				})
			})
			video.forEach(item => {
				outList.push({
					type: 'video',
					value: item
				})
			})
			voice.forEach(item => {
				outList.push({
					type: 'voice',
					value: item
				})
			})
			return outList
		},
		getAppDetail() {
			this.$http.post('/app/detail', {app_id: this.id})
				.then(res => {
					if (res.code === 200) {
						this.appInfo = res.data
						if (this.appInfo.input) {
							this.inputList = JSON.parse(res.data.input)
							if (this.fileId) {
								this.getFileHistory()
							}
						} else {
							this.isIframe = true
						}

					}
				})
		},
		getFileHistory() {
			this.$http.post('/app/history/detail', {app_id: this.id, file_id: this.fileId})
				.then(res => {
					this.handleHistory(res.data)
				})
		},
		handleHistoryChange() {
			if (this.maxPageSize === this.historyPage) {
				this.historyPage = 1
			} else {
				this.historyPage++
			}
			this.getHistory()
		},

		getHistory() {

			/**
			 *
			 * app_id
			 * :
			 * 1
			 * create_at
			 * :
			 * "Wed, 14 Aug 2024 14:04:35 GMT"
			 * params
			 * :
			 * "[{\"field\": \"text\", \"value\": \"running man\"}]"
			 * result
			 * :
			 * "http://10.155.32.13:5000/_uploads/file/2/02000585_1_2.jpg"
			 * type
			 * :
			 * "img"
			 * **/
			this.$http.post('/app/history', {
				app_id: this.id,
				page: this.historyPage,
				limit: this.historySize
			}).then(res => {
				if (res.code === 200) {
					this.historyList = res.data.data
					this.historyTotal = res.data.count
				}
			})
		},

		getHistoryResult(item) {
			if (item.status !== 2) {
				return
			}
			this.$http.get('/app/check/' + item.task_id)
			.then(res => {
				if (res.code === 200) {
					this.handleHistory({...item, ...res.data})
				}
			})
		},



		// 点击历史记录，回显表单
		handleHistory(item) {
			console.log(item)
			// 0=待处理，1-处理中，2=处理结束，-1=取消处理，-2-号常
			const {params, result, status, source} = item
			if (status !== 2) {
				let message = ''
				if (status === 0 || status === 1) {
					message = '任务未处理，请稍后再试'
				}
				if (status === -1) {
					message = '任务已取消'
				}
				if (status === -1) {
					message = '任务处理异常'
				}
				if (message) {
					this.$message.warning(message)
				}
				return
			}
			if (params) {
				const paramsO = JSON.parse(params)
				let defaultInputList = this.inputList.map(i => {
					let field = i.field
					let defaultValue = paramsO.find(it => it.field === field)?.value
					if (i.type === 'image' || i.type === 'video') {
						let defaultUrl = source[defaultValue]
						return {
							...i,
							defaultValue,
							defaultUrl
						}
					} else {
						return {
							...i,
							defaultValue
						}
					}
				})
				this.outList = this.handleResult(result)
				this.outItemView = this.outList[0]
				this.$refs.aiInputRef.setDefaultValue(defaultInputList)
			}

		},
		iframeLoad() {
			console.log('================')
		},

		startSubmitLoading() {
			this.submitTime = (storage.get(`submit-time_${this.id}`) || '30') * 1
			this.submitTimeLoading = 0;
			this.timer && clearInterval(this.timer)
			this.timer = setInterval(() => {
				let seconds = this.submitTimeLoading + 0.1
				this.submitTimeLoading = seconds.toFixed(1) * 1;
				// if (this.submitTimeLoading >= this.submitTime) {
				// 	clearInterval(timer);
				// }
			}, 100);
		},

		resetSubmitLoading () {
			console.log('resetSubmitLoading')
			this.timer && clearInterval(this.timer)
			this.submitTimeLoading = 0
			this.submitBtnLoading = false
		},


		handleSubmit(form) {
			if (!this.isLogin) {
				this.$confirm('请登录后操作', {
					showCancelButton: false
				}).then(_ => {
					this.$router.push({name: 'login', query: {redirect: window.location.hash.slice(2)}})
				})
				return
			}


			this.outList = []
			this.outItemView = {}

			const data = []
			this.inputList.map(input => {

				if (input.field === 'user') {
					data.push({
						field: input.field,
						value: storage.get('token')
					})
				} else {
					data.push({
						field: input.field,
						value: form[input.field]
					})
				}
			})
			this.startSubmitLoading()
			this.submitBtnLoading = true
			this.$http.post(`/app/submit`, {app: this.id, input: data})
				.then((res = {}) => {
					// this.outList = res.data
					// this.outItemView = this.outList[0]
					if (res.code === 200) {
						if (res?.data?.task_id) {
							this.getTaskStatus(res.data.task_id)
						}
						this.getHistory()
					} else {
						this.resetSubmitLoading()
					}
				})
				.catch(() => {
					this.resetSubmitLoading()
				})
		},

		getTaskStatus (taskId) {
			this.$http.get('/app/check/' + taskId)
			.then(res => {
				// 0=待处理，1-处理中，2=处理结束，-1=取消处理，-2异常
				if (res.code === 200 && res.data.status === 2) {
					this.outList = this.handleResult(res.data.result)
					this.outItemView = this.outList[0]

					storage.set(`submit-time_${this.id}`, this.submitTimeLoading)
					this.submitTime = this.submitTimeLoading
					this.resetSubmitLoading()
					this.getHistory()
				} else if (res.code === 200 && res.data.status === -2) {
					this.getHistory()
					this.resetSubmitLoading()
				} else {
					setTimeout(()=> {
						this.getTaskStatus(taskId)
					},10000)
				}
			}).catch(() => {
				storage.set(`submit-time_${this.id}`, this.submitTimeLoading)
				this.submitTime = this.submitTimeLoading
				this.resetSubmitLoading()
			})
		},

		handleResult (result) {
			if (!result) {
				return  []
			}
			const {image = [], text = [],  video = [], voice = []} = result
			let outList = []
			// result: {image: [], text: [], video: [], voice: []}
			image.forEach(item => {
				outList.push({
					type: 'image',
					value: item
				})
			})
			text.forEach(item => {
				outList.push({
					type: 'text',
					value: item
				})
			})
			video.forEach(item => {
				outList.push({
					type: 'video',
					value: item
				})
			})
			voice.forEach(item => {
				outList.push({
					type: 'voice',
					value: item
				})
			})
			return outList

		},

		openFullscreen() {
			let element = document.getElementById('ai-create-container')
			if (element.requestFullscreen) {
				element.requestFullscreen();
			} else if (element.mozRequestFullScreen) { // Firefox
				element.mozRequestFullScreen();
			} else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
				element.webkitRequestFullscreen();
			} else if (element.msRequestFullscreen) { // IE/Edge
				element.msRequestFullscreen();
			}
			this.$store.commit('set_full_screen', true)
		},
		exitFullscreen() {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.mozCancelFullScreen) { // Firefox
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
				document.webkitExitFullscreen();
			} else if (document.msExitFullscreen) { // IE/Edge
				document.msExitFullscreen();
			}
		},
		handleHistoryCurrentChange (page) {
			this.historyPage = page
			this.getHistory()
		}

	}
}
</script>

<style scoped lang="less">
.ai-create {
	background: #F6F7FA;
	overflow: hidden;
	height: 100vh;
	min-width: 1024px;
}


.create-main {
	flex: 1;
	display: flex;
	width: 100%;
	margin-right: 20px;
	justify-content: space-between;
	height: calc(100vh - 67px);

	.ai-input-container, .history {
		flex-shrink: 0;
		flex-grow: 0;
	}

	.ai-out-c, .ai-input-container, .history {
		height: 100%;
		overflow-y: auto;
	}
	.ai-input-container::-webkit-scrollbar, .history::-webkit-scrollbar, .ai-out-c::-webkit-scrollbar {
		display: none;
	}

	.ai-input-container {
		flex-basis: 360px;
		background: #fff;
		padding: 20px 10px 50px;
		position: relative;

		.submit-btn {
			position: fixed;
			bottom: 0;
			left: 0;
			height: 50px;
			width: 360px;
			z-index: 2000;
			background: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.header {
			margin-bottom: 10px;

			.ainame {
				font-size: 20px;
				color: #333;
				font-weight: 400;
			}

			.good, .collect {
				border-radius: 8px;
				background: #F1F2F8;
				border: 1px solid rgba(216, 218, 232, 0.16);
				font-size: 14px;
				color: #333;
				padding: 0 4px;
				text-align: center;
				margin-right: 8px;
			}
		}
	}

	.history {
		flex-basis: 284px;
		height: 100%;
		overflow-y: auto;
	}




	.ai-input, .ai-out {
		position: relative;

		.tip {
			position: absolute;
			top: 0px;
			left: 0px;
			padding: 0 5px;
			height: 26px;
			border-right: 1px solid #ddd;
			border-bottom: 1px solid #ddd;
			border-radius: 0 0 6px 0;
			background: #f9fafb;
			z-index: 4;
			color: #999;
			text-align: center;
			line-height: 26px;
			font-size: 12px;
		}

		.screen-btn {
			position: absolute;
			top: 0;
			right: 0;
			width: 30px;
			height: 26px;
			text-align: center;
			cursor: pointer;
			line-height: 26px;

			> i {
				font-size: 20px;
				color: #999;
			}

		}
	}


	.ai-input {
		width: 100%;
		background: #fff;
		overflow-y: auto;
		min-height: 300px;
		margin-bottom: 10px;
	}

	.ai-out-c {

		border: 1px solid #D8DAE8;
		border-top: none;
		border-bottom: none;
		padding: 20px;
		overflow-y: auto;
		min-height: 300px;
		margin-bottom: 10px;
		background: #f9fafb;
		flex: 1;

		.ai-out {
			background: #fff;
			min-height: 100%;
			width: 100%;
			.out-list {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				padding-top: 20px;
				.out-item-small {
					width: 50px;
					height: 50px;
					cursor: pointer;
					border-radius: 10px;
					margin: 0 10px;
					overflow: hidden;
				}
				.out-item-small.checked {
					border: 1px solid #ffb900;
					box-shadow: 0 0 6px 0 rgba(0, 0, 0, .2);
				}
			}
		}

		.screen-btn {
			display: none;
		}


	}

	.ai-history {
		width: 284px;
		//position: fixed;
		//top: 142px;
		//right: 15px;
		//z-index: 4;
		//height: calc(100vh - 160px);
		padding: 10px;
		background: #fff;
		overflow-y: auto;
		overflow-x: hidden;

		.head {
			color: #333;
			font-size: 18px;
			text-indent: 4px;
			line-height: 25px;

			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #eee;


		}
		.history-pagination {
			display: flex;
			align-items: center;
			justify-content: left;
			margin: 10px 0;
		}

	}
}

.layout.layout-fullscreen {
	flex-direction: row;
	justify-content: space-between;

	.ai-input, .ai-out {
		width: calc(50% - 5px);
		//height: 300px;
	}
}


.history-item {
	width: 260px;
	padding: 5px 10px;
	border-bottom: 1px solid #eee;
	.left {
		display: flex;
		justify-content: space-between;
		padding-right: 10px;
		height: 24px;
		align-items: center;
		margin-bottom: 5px;

		.date {
			font-size: 14px;
			color: #333;
		}

		.status {
			font-size: 12px;
			color: #666;
		}
	}



	.right {

		.title {
			color: #0B1D30;
			font-size: 14px;
		}

		.list {
			display: flex;
			flex-wrap: wrap;
			min-height: 50px;
			.out-item {
				width: 50px;
				height: 50px;
				border-radius: 4px;
				margin-bottom: 4px;
				margin-right: 4px;

				> img {
					width: 46px;
					height: 46px;
				}
			}
		}
	}
}

.history-item:hover {
	cursor: pointer;
	background: #f1f1f1;
}

#model-content {
	border: 0;
	width: 100%;
	height: calc(100vh - 67px);
}


.history-btn {
	display: none;
	position: fixed;
	top: 80px;
	width: 40px;
	height: 40px;
	right: 20px;
	color: #999;

	text-align: center;
	line-height: 40px;
	border-radius: 20px;
	z-index: 3;
	border: 1px solid #aaa;
	background: #fff;
	cursor: pointer;
}

.submit-loading {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 50%;
	z-index: 4;
	background: rgba(0, 0, 0, 0.1);
}

.submit-process {
	position: absolute;
	top: 2px;
	right: 5px;
	color: #999;
	font-size: 12px;
}

.history-change {
	cursor: pointer;
	color: #999;
	font-size: 14px;
}

.title {
	height: 30px;
	font-size: 16px;
	text-align: center;
	line-height: 30px;
}

.remark {
	font-size: 14px;
	color: #666;
	text-align: left;
	text-indent: 2em;
	line-height: 22px;
	margin-bottom: 20px;
}
</style>
