<template>
	<div class="video-container" @mouseenter="playVideo" @mouseleave="pauseVideo">
		<!--				<video-player  ref="videoPlayer"   class="video" :options="videoOptions"></video-player>-->
		<div :id="'video_waterfall_' +videoInfo.id"></div>
<!--		<div v-show="showCover && videoInfo.coverUrl" class="cover">-->
<!--			<img class="cover" :src="videoInfo.coverUrl" alt="Cover Image"/>-->
<!--		</div>-->
	</div>
</template>

<script>
import Player from 'xgplayer'

export default {
	name: "waterfull-video-item",
	props: {
		videoInfo: {
			type: Object,
			require: true,
			default: () => ({})
		}
	},
	data() {
		return {
			showCover: true,
			player: null
		}
	},
	mounted() {
		this.player = new Player({
			id: 'video_waterfall_' + this.videoInfo.id,
			url: this.videoInfo.href,
			autoplayMuted: true,
			autoplay: false,
			width: '100%',
			height: '100%',
			controls: false,
			loop: true,
			poster: this.videoInfo.coverUrl,
			icons: {
				play: null,
				pause: null
			}
		})
	},
	methods: {
		playVideo() {
			this.showCover = false;
			this.player?.seek(0);
		},


		pauseVideo() {
			this.showCover = true;

			this.player?.pause();
			// this.player?.seek(0);
		},
	}
}
</script>

<style scoped lang="less">
.video-container {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
}

.cover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
	z-index: 6;
	background: #fff;

	> img {
		max-height: 100%;
		max-width: 100%;
	}
}
</style>
