<template>
	<div class="component small-icon" >
		<img  v-show="type === 'image'" :src="src"/>
		<span   v-show="type==='video'"> <i class="iconfont icon-shipin" style="font-size: 30px;"></i> </span>
		<span v-show="type === 'voice'"> <i class="iconfont icon-yinpin" style="font-size: 26px;"></i> </span>
		<span v-show="type === 'text'"> <i class="iconfont icon-wenben" style="font-size: 30px;"></i> </span>
	</div>
</template>

<script>

export default {
	name: "small-icon",
	props: {
		type: {
			type: String,
			default: 'image'
		},
		src: {
			type: String,
			default: ''
		}
	}
}
</script>

<style scoped>
	.small-icon {
		width: 50px;
		height: 50px;
		margin-right: 5px;
		text-align: center;
		line-height: 50px;
		color: #666;
	}
	.small-icon > img {
		width: 100%;
		height: 100%;
	}
</style>
