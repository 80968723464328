<template>
	<div class="page page-suno">
		<Header/>
		<div class="suno-container">
			<div class="input">

				<div class="mode-select">
					<span @click="mode='1'" :class="`mode-item ${mode === '1'?'checked':''}`">描述模式</span>
					<span @click="mode='2'" :class="`mode-item ${mode === '2'?'checked':''}`">定制模式</span>
				</div>

				<el-form v-show="mode === '1'" label-position="top" label-width="80px" :model="form1" size="small">
					<el-form-item label="歌曲名称:" prop="name">
						<el-input v-model="form1.name" placeholder="歌曲名称比如:假期" >
						</el-input>
					</el-form-item>
					<el-form-item label="歌曲描述:" prop="desc">
						<el-input v-model="form1.desc" type="textarea" placeholder="歌曲描述 比如:关于假期的原声流行音乐" >
						</el-input>
					</el-form-item>
					<el-form-item label="是否需要歌词:" prop="verseNeed">
						<el-switch
							v-model="form1.verseNeed"
							active-text="需要"
							inactive-text="不需要" >
						</el-switch>
					</el-form-item>
					<el-form-item :label="null" prop="version">
						<el-select v-model="form1.version" placeholder="请选择版本" size="small">
							<el-option
								v-for="item in versions"
								:key="item.value"
								:label="item.label"
								:value="item.value" >
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item>
						<div style="display: flex;justify-content: right;">
							<el-button :disabled="!form1.name || !form1.desc" type="primary" size="small" @click="onSubmit1"><i
								class="iconfont icon-yinle"></i> 创作歌曲
							</el-button>
						</div>
					</el-form-item>
				</el-form>
				<el-form v-show="mode === '2'" label-position="top" label-width="80px" :model="form2" size="small">
					<el-form-item label="歌曲名称:" prop="name">
						<el-input v-model="form2.name" placeholder="歌曲名称比如:假期">
						</el-input>
					</el-form-item>
					<el-form-item label="歌曲风格:" prop="style">
<!--						<el-input v-model="form2.style" placeholder="歌曲名称比如:流行音乐">-->
<!--							<template slot="append">-->
<!--								<span style="cursor:pointer;" @click="getStyle">随机获取</span>-->
<!--							</template>-->
<!--						</el-input>-->
						<el-select
							v-model="form2.style"
							filterable
							clearable
							allow-create
							placeholder="请设置歌曲风格"
							size="small">
							<el-option
								v-for="item in styleOptions"
								:key="item.value"
								:label="item.label"
								:value="item.value">
							</el-option>
						</el-select>


					</el-form-item>
					<el-form-item label="是否需要歌词:" prop="verseNeed">
						<el-switch
							v-model="form2.verseNeed"
							active-text="需要"
							inactive-text="不需要">
						</el-switch>
					</el-form-item>
					<el-form-item label="歌词:" prop="verse" v-show="form2.verseNeed">
						<el-input v-model="form2.verse" type="textarea" :rows="4">
						</el-input>
					</el-form-item>
					<el-form-item :label="null" prop="version">
						<el-select v-model="form2.version" placeholder="请选择版本" size="small">
							<el-option
								v-for="item in versions"
								:key="item.value"
								:label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>

					<div class="continue" v-if="continueSong && continueSong.id">
						<div class="continue-head">
							<span>延伸开始于{{ form2.continue_at }}s</span>
							<span class="continue-cancel" @click="continueCancel">清除</span>
						</div>
						<el-slider v-model="form2.continue_at" :min="0"
								   :max="parseInt(continueSong.metadata?.duration + '')" :step="1"></el-slider>
						<div class="output-item">
							<div class="output-item-content">
								<div class="cover">
									<div class="cover-content">
										<img :src="continueSong.image_url" alt="">
									</div>

								</div>
								<div class="content">
									<div class="title">
										<span class="title-name">{{ continueSong.title }}</span>
										<span class="style"></span>
									</div>
									<div class="verse ellipsis">
										{{ continueSong?.metadata?.prompt }}
									</div>
									<div class="info">
										<span class="info-item time" v-show="continueSong.status === 'complete'">{{
												continueSong?.metadata?.duration
											}}s</span>
										<span class="info-item version">{{ continueSong.major_model_version }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>


					<el-form-item>
						<div style="display: flex;justify-content: space-between;">
							<div>
								<el-button type="text" @click="getServeId">生成歌词</el-button>
<!--								<el-button type="text" @click="handleUpload">上传音频</el-button>-->
							</div>
							<el-button :disabled="!form2.name || !form2.style" type="primary" size="small" @click="onSubmit2"><i
								class="iconfont icon-yinle"></i> 创作歌曲
							</el-button>
						</div>

					</el-form-item>
				</el-form>

			</div>
			<div class="output">
				<div :class="{'output-item': true, checked: item.id === checkedId}" @click="checkOneResult(item)" v-for="item in resultList" :key="item.id+item.status">
					<div class="progress-outer" v-show="item.id === checkedId">
						<el-progress :show-text="false" :stroke-width="2"
									 :percentage="audioProgressPercentage"></el-progress>
					</div>
					<div class="output-item-content">
						<div class="cover">
							<div class="cover-content">
								<img :src="item.image_url" alt="">
								<span class="btn" v-if="item.status === 'complete'">
									<i class="iconfont icon-zanting" v-if="audioStatus===1 && item.id === checkedId"
									   style="font-size: 30px;"></i>
									<i class="iconfont icon-bofang" v-else style="font-size: 40px;"></i>
								</span>
								<span class="btn" v-else style="font-size: 36px;background: rgba(0,0,0,0.4);">
									<i class="el-icon-loading" ></i>
								</span>
							</div>

						</div>
						<div class="content">
							<div class="title">
								<span class="title-name">{{ item.title }}</span>
								<span class="style">{{ item.metadata.tags }}</span>
							</div>
							<div class="verse ellipsis">
								{{ item.metadata.prompt || '无歌词' }}
							</div>
							<div class="info">
								<span class="info-item time"
									  v-show="item.status === 'complete'">{{ parseInt(item.metadata.duration) }}s</span>
								<span class="info-item version">{{ item.major_model_version }}</span>
								<span class="info-item" @click.stop="setContinueItem(item)">延伸</span>
								<span class="info-item download" @click.stop="downloadSong(item)"
									  v-show="item.status === 'complete'">
									<i style="font-size: 8px;" class="el-icon-download"></i>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="output-view">
				<div class="avatar" v-show="checkedResult && checkedResult.id">
					<img :src="checkedResult.image_large_url" alt="">
					<div class="name">{{ checkedResult?.title }}</div>
					<div class="style">歌曲风格：{{ checkedResult?.metadata?.tags }}</div>
				</div>
				<div class="verse" v-show="checkedResult && checkedResult.id">
					<div v-html="checkedResult?.metadata?.prompt"></div>
				</div>
			</div>
		</div>
		<input type="file" @change="handleUploadChange" class="file-upload-input" ref="fileUploadRef" accept="audio/*" />
	</div>
</template>

<script>
import {openaiHKRequest} from "@/util/request";
import storage from '../../util/storage'
import Header from "@/view/layout/Header";


export default {
	name: "suno-app",
	components: { Header },
	data() {
		return {
			mode: '1',
			form1: {
				name: '',
				desc: '',
				verseNeed: true,
				version: 'chirp-v3.5-0'
			},
			form2: {
				name: '',
				style: '',
				style_english: '',
				verseNeed: true,
				verse: '',
				version: 'chirp-v3.5-0',
				continue_at: 50,
				continue_clip_id: ''
			},
			styleOptions: [],
			versions: [
				{value: 'chirp-v3.5-0', label: 'version: v3.5'},
				{value: 'chirp-v3-0', label: 'version: v3'},
			],
			resultList: [
				// {
				// 	"id": "89bcd445-f6c6-4e68-818c-6b1a88d50e0a",
				// 	"video_url": "https://cdn1.suno.ai/89bcd445-f6c6-4e68-818c-6b1a88d50e0a.mp4",
				// 	"audio_url": "https://cdn1.suno.ai/89bcd445-f6c6-4e68-818c-6b1a88d50e0a.mp3",
				// 	"image_url": "https://cdn2.suno.ai/image_89bcd445-f6c6-4e68-818c-6b1a88d50e0a.jpeg",
				// 	"image_large_url": "https://cdn2.suno.ai/image_large_89bcd445-f6c6-4e68-818c-6b1a88d50e0a.jpeg",
				// 	"is_video_pending": true,
				// 	"major_model_version": "v3.5",
				// 	"model_name": "chirp-v3.5",
				// 	"metadata": {
				// 		"tags": "oriental melodic soulful",
				// 		"prompt": "[Verse]\nMoonlight softly whispers\nOver ancient streets it glows\nCherry blossoms drifting\nIn the midnight lantern's show\n\n[Verse 2]\nGolden dragons winding\nThrough the sunset-painted skies\nSilk robes gently rustling\nAs the day begins to rise\n\n[Chorus]\nOh the midnight lanterns shining bright\nGuiding us through the softest night\nWith every step our hearts take flight\nUnderneath the ancient light\n\n[Verse 3]\nBamboo forests calling\nEchoes from the past they bring\nFootsteps on the cobblestones\nTo the rhythm of the spring\n\n[Bridge]\nIn the heart of China\nWhere the lost are found\nThrough the whispers of the leaves\nWe find sacred ground\n\n[Verse 4]\nTea leaves swirling gently\nStories in the steam arise\nListening to the winds that say\nSecrets of these ageless ties",
				// 		"gpt_description_prompt": "\u4e2d\u56fd\u98ce",
				// 		"type": "gen",
				// 		"duration": 157.28,
				// 		"refund_credits": false,
				// 		"stream": true
				// 	},
				// 	"is_liked": false,
				// 	"user_id": "17d709cc-5af0-449d-81b0-879c9c6d80c7",
				// 	"display_name": "AbstractDisco4190",
				// 	"handle": "abstractdisco4190",
				// 	"is_handle_updated": false,
				// 	"avatar_image_url": "https://cdn1.suno.ai/defaultPink.webp",
				// 	"is_trashed": false,
				// 	"created_at": "2024-10-09T13:32:13.082Z",
				// 	"status": "complete",
				// 	"title": "Midnight Lanterns",
				// 	"play_count": 0,
				// 	"upvote_count": 0,
				// 	"is_public": false
				// },
				// {
				// 	"id": "7c06702c-ce2e-4ca8-a991-ef422abf88a5",
				// 	"video_url": "https://cdn1.suno.ai/7c06702c-ce2e-4ca8-a991-ef422abf88a5.mp4",
				// 	"audio_url": "https://cdn1.suno.ai/7c06702c-ce2e-4ca8-a991-ef422abf88a5.mp3",
				// 	"image_url": "https://cdn2.suno.ai/image_7c06702c-ce2e-4ca8-a991-ef422abf88a5.jpeg",
				// 	"image_large_url": "https://cdn2.suno.ai/image_large_7c06702c-ce2e-4ca8-a991-ef422abf88a5.jpeg",
				// 	"is_video_pending": false,
				// 	"major_model_version": "v3.5",
				// 	"model_name": "chirp-v3.5",
				// 	"metadata": {
				// 		"tags": "oriental melodic soulful",
				// 		"prompt": "[Verse]\nMoonlight softly whispers\nOver ancient streets it glows\nCherry blossoms drifting\nIn the midnight lantern's show\n\n[Verse 2]\nGolden dragons winding\nThrough the sunset-painted skies\nSilk robes gently rustling\nAs the day begins to rise\n\n[Chorus]\nOh the midnight lanterns shining bright\nGuiding us through the softest night\nWith every step our hearts take flight\nUnderneath the ancient light\n\n[Verse 3]\nBamboo forests calling\nEchoes from the past they bring\nFootsteps on the cobblestones\nTo the rhythm of the spring\n\n[Bridge]\nIn the heart of China\nWhere the lost are found\nThrough the whispers of the leaves\nWe find sacred ground\n\n[Verse 4]\nTea leaves swirling gently\nStories in the steam arise\nListening to the winds that say\nSecrets of these ageless ties",
				// 		"gpt_description_prompt": "\u4e2d\u56fd\u98ce",
				// 		"type": "gen",
				// 		"duration": 164.72,
				// 		"refund_credits": false,
				// 		"stream": true
				// 	},
				// 	"is_liked": false,
				// 	"user_id": "17d709cc-5af0-449d-81b0-879c9c6d80c7",
				// 	"display_name": "AbstractDisco4190",
				// 	"handle": "abstractdisco4190",
				// 	"is_handle_updated": false,
				// 	"avatar_image_url": "https://cdn1.suno.ai/defaultPink.webp",
				// 	"is_trashed": false,
				// 	"created_at": "2024-10-09T13:32:13.082Z",
				// 	"status": "complete",
				// 	"title": "Midnight Lanterns",
				// 	"play_count": 0,
				// 	"upvote_count": 0,
				// 	"is_public": false
				// }
			],
			checkedId: '',
			audioObject: null,
			audioStatus: 2, // 1 播放 2 暂停
			audioProgressPercentage: 0,
			// 延伸
			continueSongId: ''
		}
	},
	mounted() {
		this.audioObject = new Audio()

		this.audioObject.addEventListener('timeupdate', () => {
			if (this.audioObject) {
				// 获取当前播放时间（秒）
				let currentTime = this.audioObject.currentTime;

				// 获取音频总时长（秒）
				let duration = this.audioObject.duration;

				// 计算播放进度百分比
				this.audioProgressPercentage = (currentTime / duration) * 100;
			}
		});

		this.resultList = storage.get('suno_result') || []


		this.styleOptions = storage.get('suro_style_option') || this.getStyleOptions()
		// if (this.resultList.length > 1) {
		// 	let ids = this.resultList.slice(0,2).map(i => i.id).join(',')
		// 	this.getSong(ids)
		// }
	},
	beforeDestroy() {
		if (this.audioObject) {
			this.audioObject.pause();
			this.audioObject.currentTime = 0;
			this.audioObject.removeEventListener('timeupdate', () => {
			})
		}
	},
	computed: {
		checkedResult() {
			// return this.resultList[0]
			return this.resultList.find(item => item.id === this.checkedId) || {}
		},
		continueSong() {
			return this.resultList.find(item => item.id === this.continueSongId) || {}
		}
	},
	methods: {
		checkOneResult(item) {
			if (item.status !== 'complete') {
				this.checkedId = item.id
				this.$message.warning('歌曲正在创作中，请稍后再试')
				return
			}
			if (item.id === this.checkedId) {
				this.audioToggle()
			} else {
				this.checkedId = item.id
				this.audioObject.src = item.audio_url
				this.audioStatus = 1
				this.audioProgressPercentage = 0
				this.audioObject.play()
			}

		},
		audioToggle() {

			if (this.audioStatus === 1) {
				this.audioStatus = 2
				this.audioObject.pause()
			} else {
				this.audioStatus = 1
				this.audioObject.play()
			}
		},
		getSong(id) {
			openaiHKRequest('/sunoapi/feed/' + id, {}, {method: 'get'})
				.then(res => {
					if (res && res.length > 0) {

						res.map(item => {
							if (item.status !== 'complete') {
								setTimeout(() => {
									this.getSong(item.id)
								}, 5000)
							}

							let index = this.resultList.findIndex(i => i.id === item.id)
							if (index === -1) {
								this.resultList.unshift(item)
							} else {
								this.resultList.splice(index, 1, item)
							}
						})

						storage.set('suno_result', this.resultList)
					}
				})
		},
		onSubmit1() {
			let data = {
				"gpt_description_prompt": this.form1.desc,
				"make_instrumental": !this.form1.verseNeed,
				"mv": this.form1.version,
				"prompt": this.form1.name
			}
			openaiHKRequest('/sunoapi/generate/description-mode', data)
				.then(res => {
					if (res?.clips && Array.isArray(res.clips)) {
						let ids = res.clips.map(item => {
							return item.id
						}).join(',')
						this.getSong(ids)
					}
				})
		},
		downloadSong(item) {
			const link = document.createElement('a');
			link.href = item.audio_url;
			link.target = '_blank'
			link.download = item.display_name + '.mp3';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
		// 延伸
		setContinueItem(item) {
			this.continueSongId = item.id
			this.form2.continue_at = parseInt((this.continueSong?.metadata?.duration / 2 + ''))
			this.form2.continue_clip_id = item.id
		},
		// 取消延伸
		continueCancel() {
			this.continueSongId = ''
			this.form2.continue_at = 0
			this.form2.continue_clip_id = ''
		},

		getStyle() {
			const s = ["acoustic", "aggressive", "anthemic", "atmospheric", "bouncy", "chill", "dark", "dreamy", "electronic", "emotional", "epic", "experimental", "futuristic", "groovy", "heartfelt", "infectious", "melodic", "mellow", "powerful", "psychedelic", "romantic", "smooth", "uplifting"];
			const s_cn = ['自然的', '进取的', '赞美的', '大气的',                '有弹性的', '寒冷的', '深色的', '梦幻的', '电子的', '鼓舞的',         '史诗的', '试验性的', '未来派的', '时髦的',          '真心的', '传染性的', '和谐的', '醇厚的', '强大的', '迷幻的',                    '浪漫的','光滑的','振奋人心的']
			console.log(s.length, s_cn.length)
			const l = ["afrobeat", "anime", "ballad",     "bluegrass", "blues", "classical", "country", "cumbia", "dance", "dancepop", "delta blues", "electropop", "disco", "dream pop", "drum and bass",   "folk", "funk", "future bass", "gospel", "grunge", "grime", "hip hop", "house", "indie", "j-pop", "jazz", "k-pop", "kids music", "metal",                 "opera", "pop", "punk", "raga", "rap", "reggae",            "reggaeton", "rock", "rumba", "salsa", "samba", "sertanejo",       "soul", "swing", "synthwave", "techno"];
			const l_cn = ['非洲节奏','动画音乐','民谣',         '蓝草音乐','蓝调','古典','乡村',                  '昆比亚','舞蹈','流行舞曲','三角洲蓝调','流行电子乐',              '迪斯科','梦幻流行音乐','鼓和贝斯乐',           '民谣','疯克音乐','未来贝斯',     '福音','垃圾摇滚','英式说唱','嘻哈',        '浩室音乐','独立音乐','日本流行音乐','爵士乐','韩国流行音乐','儿童音乐','金属乐',           '歌剧','流行音乐','朋克','印度拉格音乐','说唱','雷鬼音乐',        '雷击顿乐','摇滚','伦巴','莎莎舞','桑巴','巴西乡村音乐',                  '索尔音乐','摇摆乐','电子合成器乐',           '科技舞曲']
			console.log(l.length, l_cn.length)

			let ind1 = Math.floor(Math.random() * s.length);
			let ind2 = Math.floor(Math.random() * l.length);
			this.form2.style = s_cn[ind1] + ' ' + l_cn[ind2]
			this.style_english = s[ind1] + ' ' + l[ind2]
		},

		getStyleOptions () {
			const s = ["romantic", "smooth", "uplifting", "acoustic", "aggressive", "anthemic", "atmospheric", "bouncy", "chill", "dark", "dreamy", "electronic", "emotional", "epic", "experimental", "futuristic", "groovy", "heartfelt", "infectious", "melodic", "mellow", "powerful", "psychedelic", ];
			const s_cn = ['浪漫的','光滑的','振奋人心的',     '自然的', '进取的', '赞美的', '大气的',                '有弹性的', '寒冷的', '黑暗的', '梦幻的', '电子的', '鼓舞的',         '史诗的', '试验性的', '未来派的', '时髦的',          '真心的', '传染性的', '和谐的', '醇厚的', '强大的', '迷幻的',                    ]

			const l = ["ballad",     "blues", "classical", "country", "cumbia", "dance", "dancepop", "delta blues", "electropop", "disco", "dream pop", "drum and bass",                      "funk", "future bass",  "grunge", "grime", "hip hop", "house", "indie", "j-pop", "jazz", "k-pop", "kids music", "metal",                 "opera", "pop", "punk", "raga", "rap", "reggae",            "reggaeton", "rock", "rumba", "salsa", "samba", "sertanejo",       "soul", "swing", "synthwave", "techno"];
			const l_cn = ['民谣',         '蓝调','古典音乐','乡村音乐',                  '昆比亚','舞蹈乐','流行舞曲','三角洲蓝调','流行电子乐',              '迪斯科','梦幻流行音乐','鼓和贝斯乐',           '疯克音乐','未来贝斯',    '垃圾摇滚','英式说唱','嘻哈',        '浩室音乐','独立音乐','日本流行音乐','爵士乐','韩国流行音乐','儿童音乐','金属乐',           '歌剧','流行音乐','朋克','印度拉格音乐','说唱','雷鬼音乐',        '雷击顿乐','摇滚','伦巴','莎莎舞','桑巴','巴西乡村音乐',                  '索尔音乐','摇摆乐','电子合成器乐',           '科技舞曲']

			let options = []
			for (let i = 0; i < s_cn.length; i++) {
				for (let j = 0; j < l_cn.length; j++) {

					let text = s_cn[i] + l_cn[j]
					options.push({
						value: text,
						label: text
					})

				}
			}
			return options

		},


		// 获取歌词id
		getServeId() {
			if (!this.form2.name) {
				this.$message.warning('请输入歌曲名称')
				return
			}
			openaiHKRequest('/sunoapi/generate/lyrics/', {"prompt": this.form2.name})
				.then(res => {
					if (res.id) {
						this.getServeById(res.id)
					}
				})
		},
		getServeById(id) {
			openaiHKRequest('/sunoapi/lyrics/' + id, {}, {method: 'get'})
				.then(res => {
					if (res.status === 'complete') {
						this.form2.verse = res.text
					} else {
						setTimeout(() => {
							this.getServeById(id)
						}, 5000)
					}
				})
		},

		// 如果有新增的音乐风格选项，处理，加到备选项

		updateStyleOptions () {
			let style = this.form2.style
			if (this.styleOptions.findIndex(i => i.value === style) === -1) {
				this.styleOptions.unshift({
					value: style,
					label: style
				})
				storage.set('suro_style_option', this.styleOptions)
			}
		},

		onSubmit2() {
			let data = {
				"prompt": !this.form2.verseNeed ? this.form2.verse : '',
				"mv": this.form2.version,
				"title": this.form2.name,
				"tags": this.form2.style,
				"continue_at": this.form2.continue_at,
				"continue_clip_id": this.form2.continue_clip_id
			}

			this.updateStyleOptions()

			openaiHKRequest('/sunoapi/generate', data)
				.then(res => {
					if (res?.clips && Array.isArray(res.clips)) {
						let ids = res.clips.map(item => {
							return item.id
						}).join(',')
						this.getSong(ids)
					}
				})
		},
		handleUpload () {
			this.$refs.fileUploadRef.click()
		},
		handleUploadChange (e) {
			const file = e.target.files[0];
			if (!file) {
				return;
			}
			const formData = new FormData();
			formData.append('file', file);


			const startUpload = () => {
				openaiHKRequest('/sunoapi/uploads/audio', {extension: 'mp3'})
				.then(res => {
					const id = res.id
					const url = res.url
//raw_uploads/c25a8c59-000a-481f-ac28-efde2dc9e677.mp3
					let name = id+'.mp3'
					formData.append('key', name)
					formData.append('Content-Type', 'audio/mpeg')
					formData.append('AWSAccessKeyId','AKIA2V4GXGDKJMTPWLXO')
					formData.append('policy','eyJleHBpcmF0aW9uIjogIjIwMjQtMDYtMTdUMDY6MTg6MzJaIiwgImNvbmRpdGlvbnMiOiBbWyJjb250ZW50LWxlbmd0aC1yYW5nZSIsIDAsIDEwNDg1NzYwMF0sIFsic3RhcnRzLXdpdGgiLCAiJENvbnRlbnQtVHlwZSIsICJhdWRpby9tcGVnIl0sIHsiYnVja2V0IjogInN1bm8tdXBsb2FkcyJ9LCB7ImtleSI6ICJyYXdfdXBsb2Fkcy9jMjVhOGM1OS0wMDBhLTQ4MWYtYWMyOC1lZmRlMmRjOWU2NzcubXAzIn1dfQ==')
					formData.append('signature','OHwjC5okNSdPzg8W4PePwfR29pg=')

					this.$http.post(url, formData, {
						headers: {
							'content-type': 'multipart/form-data'
						}
					}).then(res => {
						this.uploadFinish(id, name)
					})
				})

			}

			startUpload()
		},
		uploadFinish (id, name) {
			openaiHKRequest(`/sunoapi/uploads/audio/${id}/upload-finish`, {"upload_type":"file_upload","upload_filename":name})
			.then(res => {
				this.getUploadResult(id)
			})
		},
		getUploadResult (id) {
			openaiHKRequest('/sunoapi/uploads/audio/' + id,)
			.then(res => {
				if (res.status === 'complete') {
					this.initializeClip(res.s3_id)
				} else {
					setTimeout(() => {
						this.getUploadResult(id)
					}, 5000)
				}
			})
		},
		// /sunoapi/uploads/audio/{id}/initialize-clip
		initializeClip () {
			openaiHKRequest(`/sunoapi/uploads/audio/${id}/initialize-clip`)
			.then(res => {
				// res.clip_id
				this.getSong(res.clip_id)
			})
		},
	}
}
</script>

<style scoped lang="less">
.page-suno {
	//padding-left: 60px;
}

.suno-container {
	height: calc(100vh - 67px);
	display: flex;
	background: #fff;

	.input, .output-view, .output {
		height: 100%;
		overflow-y: auto;
	}

	.input, .output-view {
		flex-basis: 300px;
		flex-grow: 0;
		flex-shrink: 0;
	}

	.output {
		flex: 1;
		background-color: #f9fafb;
	}
}

.input {
	padding: 8px;

	.mode-select {
		display: flex;
		justify-content: center;
		background: rgba(0,0,0, 0.1);
		width: 284px;
		height: 40px;
		margin: 0 auto;
		padding: 3px;
		font-size: 14px;

		.mode-item {
			width: 139px;
			text-align: center;
			color: #666;
			padding: 6px 0;
			cursor: pointer;
			border-radius: 4px;
			transition: background-color 0.3s;
		}

		.mode-item.checked {
			background: rgba(0,0,0,0.06);
		}
	}
}

.output-item {
	position: relative;

	.progress-outer {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}
}

.output-item.checked, .output-item:hover {
	background: #e9fafb;
}

.output-item-content {
	height: 76px;
	padding: 8px;
	display: flex;
	border-bottom: 1px solid rgba(107, 114, 128, 0.1);
	position: relative;


	.cover {
		flex-basis: 60px;
		flex-shrink: 0;
		flex-grow: 0;
		background: #FF661E;

		.cover-content {
			width: 60px;
			height: 60px;
			position: relative;

			.btn {
				position: absolute;
				top: 0;
				left: 0;
				width: 60px;
				height: 60px;
				line-height: 60px;
				text-align: center;

				> i {
					color: #fff;
					cursor: pointer;
				}
			}

			img {
				width: 100%;
				height: 100%;
			}
		}


	}

	.content {
		flex: 1;
		width: calc(100% - 60px);
		padding: 0 8px;

		.title {
			display: flex;
			justify-content: space-between;
			height: 22px;

			.title-name {
				color: rgba(0,0,0, 0.82);
				font-size: 14px;
			}

			.style {
				color: rgba(0,0,0, 0.82);
				opacity: 0.8;
				font-size: 14px;
			}
		}

		.verse {
			font-size: 12px;
			color: rgba(0,0,0, 0.82);
			opacity: 0.6;
			height: 20px;
			width: 100%;
		}

		.info {
			display: flex;
			justify-content: right;
			font-size: 8px;

			&-item {
				cursor: pointer;
				color: rgba(0,0,0, 0.82);
				margin: 0 4px;
				padding: 1px 3px;
				border: 0.8px solid rgba(107, 114, 128, 0.3);
				border-radius: 6px;
			}
		}
	}
}

.output-view {
	color: #666;

	.avatar {
		height: 300px;
		width: 100%;
		position: relative;


		img {
			width: 100%;
			height: 100%;
		}

		.name, .style {
			position: absolute;
			right: 0;
			height: 24px;
			padding-right: 10px;
			color: #fff;
		}

		.style {
			bottom: 4px;
			font-size: 12px;
		}

		.name {
			bottom: 32px;
		}
	}

	.verse {
		padding: 10px;
		white-space: pre;
	}
}

.continue {
	color: #fff;

	&-head {
		display: flex;
		justify-content: space-between;
		color: rgba(0,0,0, 0.82);
		opacity: 0.6;
		font-size: 14px;

		.continue-cancel {
			cursor: pointer;
		}
	}
}


.file-upload-input {
	width: 0;
	height: 0;
	display: block;
	opacity: 0;
}

</style>
