<template>
	<div class="layout">
		<!--		<div class="show-px">{{width}} × {{ height}}</div>-->
		<Header/>
		<div class="main">
			<Aside v-show="showAside"/>

			<div class="container" id="layout-container" @scroll="handleScrollDebounce" ref="scrollDiv">
				<transition name="fade" mode="out-in">
					<router-view/>
				</transition>
			</div>
		</div>



	</div>
</template>

<script>
import Header from "./Header";
import Aside from './Aside'
import {debounce} from 'lodash'
import vm from '../../util/eventUtil'

export default {
	name: "layout",
	components: {Header, Aside},
	watch: { // 监听,当路由发生变化的时候执行
		'$route': { // $route可以用引号，也可以不用引号
			handler(to, from) {
				console.log('当前页面路由：' + to.name); //新路由信息
			},
			deep: true, // 深度观察监听
			immediate: true, // 第一次初始化渲染就可以监听到
		}
	},

	data() {
		return {
			width: 0,
			height: 0,
			showAside: true
		}
	},
	created() {
		this.$store.dispatch('getUserInfo')
	},
	mounted() {
		this.monitorWindowSize((width, height) => {
			this.width = width
			this.height = height
		})
	},
	beforeDestroy() {
	},
	methods: {
		handleScrollDebounce: debounce(function () {
			// 只有home页和文件页需要监听滚动
			if (this.$route.name !== 'home' && this.$route.name !== 'myfile') {
				return
			}
			const scrollDiv = this.$refs.scrollDiv;
			const isScrolledToBottom = scrollDiv.scrollHeight - scrollDiv.scrollTop < scrollDiv.clientHeight + 30;
			if (isScrolledToBottom) {
				console.log('已滑动到底部');
				vm.$emit('scroll-bottom')
			} else {
				// console.log('未滑动到底部');
			}
		}, 500),
		monitorWindowSize(callback) {
			// Function to handle resize events and invoke the callback
			function handleResize() {
				const width = window.innerWidth;
				const height = window.innerHeight;
				callback(width, height);
			}

			// Attach the resize event listener
			window.addEventListener('resize', handleResize);

			// Call handleResize initially to provide the current size
			handleResize();

			// Return a function to remove the event listener if needed
			return function stopMonitoring() {
				window.removeEventListener('resize', handleResize);
			};
		}


	}
}
</script>

<style lang="less">
.layout {
	overflow-y: hidden;
}


.main {
	display: flex;
	height: calc(100vh - 67px);
	width: 1080px;
	margin: 0 auto;
	background: #F6F7FA;

	.aside {
		display: none;
		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: 220px;
		padding-left: 20px;
		padding-right: 20px;
		border-right: 1px solid #EAECEF;
		overflow-y: auto;
		height: calc(100vh - 66px);
		padding-bottom: 100px;
	}

	.container {
		width: 100%;
		background: #F6F7FA;
		height: 100%;
		overflow-y: auto;
	}
}

.show-px {
	position: fixed;
	top: 0;
	left: 10px;
	color: #999;
	font-size: 12px;
}

@media screen and (min-width: 1440px) {

	.header-container, .ai-create {
		.header {
			width: 100%;

			.logo1 {
				display: none;
			}
		}
	}

	.main {
		width: 100%;

		.aside {
			display: block;
			flex-basis: 300px;
		}
	}
}


</style>
