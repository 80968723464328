import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'


Vue.use(Vuex)


export default new Vuex.Store({

    modules: {
        user
    },
    getters: {
        isLogin: state => state.user.userInfo && state.user.userInfo.id,
        userInfo: state => state.user.userInfo,
        showAside: state => state.user.showAside,
        fullscreen: state => state.user.fullscreen,
        likeList: state => (state.user.likeList),
        favoriteList: state => (state.user.favoriteList),
    }
    // plugins: [
    //   createPersistedState(),
    //   createSharedMutations()
    // ],
    // strict: process.env.NODE_ENV !== 'production'
})

