<template>
	<div
		element-loading-text="正在上传..."
		element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(200, 200, 200, 0.9)"
		v-loading="loading"

		class="camera-image" >
		<video v-show="!!stream" autoplay playsinline ref="preview" width="300" height="200"></video>
		<!--		<button @click="startCamera">打开摄像头</button>-->
		<!--		<button v-if="stream" @click="takePhoto">拍照</button>-->

		<el-button type="text" size="small" class="record-btn record-btn2" v-if="!!stream" @click="takePhoto">拍照
		</el-button>
		<el-button type="text" size="small" class="record-btn record-btn1" v-else @click="startCamera">打开摄像头</el-button>

	</div>
</template>

<script>
import {Base64} from "js-base64";
import {uploadFile} from "@/util/request";

export default {
	data() {
		return {
			stream: null,
			photo: null,
			loading: false
		};
	},
	methods: {
		async startCamera() {
			try {
				this.stream = await navigator.mediaDevices.getUserMedia({video: true, audio: false});
				this.$refs.preview.srcObject = this.stream;
			} catch (error) {
				console.error("无法访问摄像头：", error);
			}
		},
		async takePhoto() {
			if (!this.stream) return;
			const canvas = document.createElement('canvas');
			canvas.width = this.$refs.preview.videoWidth;
			canvas.height = this.$refs.preview.videoHeight;
			const ctx = canvas.getContext('2d');
			ctx.drawImage(this.$refs.preview, 0, 0, canvas.width, canvas.height);
			this.photo = canvas.toDataURL('image/png');
			this.base64ToFile(this.photo)
			this.stream.getTracks().forEach((track) => track.stop());
		},
		base64ToFile(base64, fileName = Date.now() + '.png') {
			let arr = base64.split(',');
			let mime = arr[0].match(/:(.*?);/)[1];
			let bstr = atob(arr[1]);
			let n = bstr.length;
			let u8arr = new Uint8Array(n);

			while(n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}

			// 创建Blob对象
			let blob = new Blob([u8arr], {type: mime});

			// 创建File对象
			let file = new File([blob], fileName, {type: mime});
			this.loading = true
			uploadFile(file).then(res => {
				console.log('upload res===', res)
				this.$emit('complete', res.url, res.id)
			})
			.finally(() => {
				this.loading = false
			})
		}

	},
	beforeDestroy() {
		if (this.stream) {
			this.stream.getTracks().forEach((track) => track.stop());
		}
	},
};
</script>

<style scoped lang="less">
.camera-image {
	width: 300px;
	height: 200px;
	position: relative;
	border: 1px dashed #ccc;
	border-radius: 5px;

	.record-btn {
		position: absolute;
	}

	.record-btn1 {
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
	}

	.record-btn2 {
		bottom: 5px;
		right: 5px;
	}
}

</style>
