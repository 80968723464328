<template>

	<div class="image-list">
		<div class="image-item" v-for="(image, index) in list" :key="index">
			<el-image  :key="image.href" :src="image.href"  lazy></el-image>
			<div class="info">
				<div class="info-left">
<!--					<div class="avatar"> </div>-->
<!--					<div class="username">小花椒 </div>-->
				</div>
				<div class="info-right">
					<div class="good" @click="handleLike(image)">
						<i v-if="likeList.map(i => i.id).indexOf(image.id) > -1" style="color: #FFb900;" class="iconfont icon-dianzan"></i>
						<i v-else class="iconfont icon-dianzan"></i>
						{{ likeList.find(i => i.id === image.id)?.like || 0 }}</div>
					<div class="collect" @click="handleFavorite(image)">
						<i v-if="favoriteList.map(i => i.id).indexOf(image.id) > -1" style="color: #FFb900;" class="iconfont icon-shoucang"></i>
						<i v-else class="iconfont icon-shoucang"></i>
						{{ favoriteList.find(i => i.id === image.id)?.like || 0 }}
					</div>
				</div>
			</div>

		</div>
	</div>
<!--	<waterfall  :col="4" :data="list"  @loadmore="loadmore"  @scroll="scroll" :lazyDistance="500">-->
<!--		<template>-->
<!--			<div class="waterfall-cell-item" v-for="(item,index) in list">-->

<!--				<img-->
<!--					:src="item.href" :alt="item.alt"-->
<!--					:lazy-src="item.href"-->
<!--					:title="item.title"-->
<!--				/>-->
<!--				<div class="info">-->
<!--					<div class="info-left">-->
<!--						<div class="avatar"> </div>-->
<!--						<div class="username">小花椒 </div>-->
<!--					</div>-->
<!--					<div class="info-right">-->
<!--						<div class="good" @click="handleLike(item)"> <i class="iconfont icon-dianzan"></i> 16</div>-->
<!--						<div class="collect"> <i class="iconfont icon-shoucang"></i> 16</div>-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->
<!--		</template>-->
<!--	</waterfall>-->
</template>

<script>
import vm from '../util/eventUtil'
import {mapGetters} from "vuex";

export default {
	name: 'water-fall2',
	data() {
		return {
			list: [],
			page: 1,
			pageSize: 6,
			total: 0,
			category: ''
		}
	},
	mounted() {
		this.getData()
		// vm.$on('scroll-bottom', this.loadmore)
	},
	computed: {
		...mapGetters(['likeList', 'favoriteList'])
	},
	beforeDestroy() {
	},
	methods: {
		// 分类筛选
		filter (category = '') {
			if (category === this.category) {
				return
			}
			this.page = 1
			this.category = category || ''
			this.getData(true)
		},
		getData (isLoad) {
			let url = `/file/art/list?page=${this.page}&limit=${this.pageSize}`
			if (this.category) {
				url += `&category=${this.category}`
			}
			this.$http.get(url)
			.then(res => {
				if (res.code === 200) {
					if (isLoad) {
						this.list = res.data.data
					} else {
						this.list = this.list.concat(res.data.data)
					}
					this.total = res.data.count
				}
			})
		},
		loadmore () {
			console.log('====== load more =====')
			if (this.total <= this.list.length) {
				return
			}
			this.page++
			this.getData()
		},
		// 点赞
		handleLike (file) {
			this.$http.post('/file/sms/like', {file_id: file.id})
			.then(res => {
				if (res.code === 200) {
					this.$store.dispatch('getLikeList')
				}
			})
		},
		handleFavorite (file) {
			this.$http.post('/file/sms/favorite', {file_id: file.id})
			.then(res => {
				if (res.code === 200) {
					this.$store.dispatch('getFavoriteList')
				}
			})
		},
		// handleScroll() {
		// 	const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
		// 	const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
		// 	const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
		// 	if (scrollTop + clientHeight >= scrollHeight - 5) {
		// 		this.loadmore();
		// 	}
		// },
	}
}

</script>

<style scoped lang="less">
.image-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: left;
}

.image-item {
	width: calc(33.33% - 10px);
	height: 300px;
	margin-bottom: 20px;
	margin-right: 10px;
	overflow: hidden;
}

.image-item img {
	width: 100%;
	height: 300px;
}

.loading {
	text-align: center;
	margin-top: 20px;
}
















//.waterfall-cell-item {
//	img {
//		width: 100%;
//		height: auto!important;
//	}
//}
//
//
//.waterfall-cell-item {
//	margin-bottom: 5px;
//	overflow: hidden;
//	position: relative;
//	border-radius: 8px;
.image-item{
	position: relative;
	.info {
		position: absolute;
		left: 20px;
		right: 20px;
		bottom: 10px;
		height: 26px;
		display: flex;
		justify-content: space-between;

		.info-left {
			display: flex;
			align-items: center;
			.avatar {
				height: 26px;
				width: 26px;
				border-radius: 13px;
				background: #A1A3A9;
			}
			.username {
				color: #fff;
				font-size: 13px;
				margin-left: 10px;
			}
		}
		.info-right {
			display: flex;
			align-items: center;
			color: #fff;
			font-size: 13px;
			.good,.collect {
				cursor: pointer;
			}
			.collect {
				margin-left: 10px;
			}
		}
	}
}
//}
//.waterfall-cell-item .text{
//	position: absolute;
//	z-index: 9999;
//	top: 0px;
//	right: 0px;
//	left: 0px;
//	text-align: center;
//	background-color: rgba(30, 29, 29, 0.5); /* 设置背景颜色为白色，透明度为0.5 */
//	color: aliceblue;
//	transition: 0.3s;
//	transform: translateY(-100px);
//}
//.waterfall-cell-item .img{
//	transition: 0.8s;
//}
//.waterfall-cell-item .img:hover {
//	transform: scale(1.2);
//}
//.waterfall-cell-item:hover .text{
//	transform: translateY(0px);
//}
</style>
