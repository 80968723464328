<template>
	<div class="aside">
		<div class="aside-container">
			<div
				v-for="(item, ind) in titleList"
				:key="ind"
				:class="`${item.class} ${item.name === titleChecked ? 'checked' : ''}`"
				@click="setTitleCheck(ind, item)"
			>
				<b></b>
				<i v-if="!!item.icon" :class="`iconfont ${item.icon}`"></i>
				{{ item.title }}
			</div>
			<div class="other-link">
				<div class="link-item bilibili" @click="linkOtherWeb('https://www.bilibili.com/')">
					B站
				</div>
				<div class="link-item douyin" @click="linkOtherWeb('https://www.douyin.com/')">抖音</div>
			</div>
		</div>
		<div class="icp">
			<p class="ellipsis">{{ $18n.company_name }}</p>
			<p class="ellipsis">{{ $18n.icp }}</p>
			<p class="ellipsis">{{ $18n.address }}</p>
			<p class="ellipsis">联系方式：{{ $18n.contact }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "layout-aside",
	data() {
		return {
			titleList: [
				{class: 'small-title', title: '探索'},
				{class: 'title', title: '首页', icon: 'icon-shouye', name: 'home'},
				{class: 'title', title: '工具集', icon: 'icon-aigongju-01', name: 'apps'},
				{class: 'line', title: ''},

				// {class: 'small-title', title: '我的'},
				// {class: 'title', title: '我的创作', icon: 'icon-chuangzuo'},
				{class: 'title', title: '我的文件', icon: 'icon-wenjian', name: 'myfile'},
				// {class: 'title', title: '小组文件', icon: 'icon-wenjian'},
				// {class: 'title', title: '我的收藏', icon: 'icon-shoucang'},
				{class: 'line', title: ''},

				// {class: 'title', title: '热门活动', icon: 'icon-remenhuodong'},
				{class: 'title', title: '反馈', icon: 'icon-fankui', name: 'feedback'},
				// {class: 'title', title: '个人设置', icon: 'icon-gerenshezhi'},
				// {class: 'line', title: ''},
			],
			titleChecked: '',
		}
	},
	mounted() {
		this.setDefaultChecked()
	},
	methods: {
		setDefaultChecked() {
			this.titleChecked = this.$route.name || 'home'
		},
		setTitleCheck(ind, item) {
			this.titleChecked = item.name
			if (item.name) {
				this.$router.push({name: item.name})
			} else {
				this.$message.info('敬请期待')
			}
			setTimeout(() => {
				this.$store.commit('set_aside_show', false)
			}, 100)

		},
		linkOtherWeb(url) {
			window.open(url)
		}
	}
}
</script>

<style lang="less">

.aside {
	background: #fff;

	.title, .small-title {
		text-indent: 16px;
	}

	.small-title {
		font-size: 16px;
		color: #9DA3AE;
		height: 22px;
		line-height: 22px;
		margin-top: 20px;
		margin-bottom: 12px;
	}

	.title {
		height: 60px;
		margin-top: 8px;
		border-radius: 12px;
		color: #333333;
		font-size: 18px;
		line-height: 36px;
		display: flex;
		align-items: center;
		cursor: pointer;
		position: relative;
	}

	.title.checked, .title:hover {
		background: #F4F5F9;
	}

	.title.checked {
		> b {
			position: absolute;
			left: -19px;
			width: 20px;
			height: 70px;
			background: url("../../assets/image/menuchecked.png") no-repeat right center;
			background-size: 161px;
			animation: fadeInOut 0.5s;
		}

		> i {
			color: #FF661E;
		}
	}

	@keyframes fadeInOut {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	.line {
		background: #F3F4F6;
		height: 1px;
		width: 100%;
		margin-top: 8px;

	}


	.other-link {
		display: flex;
		justify-content: center;
		margin-top: 16px;

		.link-item {
			width: 70px;
			height: 32px;
			border: 1px dashed #D8DAE8;
			border-radius: 6px;
			text-align: center;
			line-height: 32px;
			margin: 0 12px;
			color: #666666;
			cursor: pointer;
		}

		.bilibili {
			background: url("../../assets/image/bilibili.png") no-repeat 2px center;
			text-indent: 14px;
		}

		.douyin {
			background: url("../../assets/image/douyin.png") no-repeat 4px center;
			text-indent: 14px;
		}
	}

	.aside-container {
		min-height: 100%;
		padding-top: 0.1px;
	}

	.icp {
		font-size: 12px;
		margin: 0 -15px -100px;
		height: 100px;
		line-height: 20px;
		color: #9DA3AE;
		text-indent: 16px;
		text-align: left;
		padding-top: 12px;

		> p {
		}
	}

}

</style>
