<template>
	<div class="login">
		<Header/>

		<div class="login-container">
			<div class="title">
				<div class="logo"><img src="../assets/image/wanxianglogo2.png" alt=""></div>
			</div>
			<el-form v-show="loginType === '1'" ref="form" label-width="80px" :model="form" :rules="rules"
					 :label-position="'top'">
				<el-form-item label="手机号:" prop="phone">
					<el-input v-model="form.phone">
						<template slot="prepend">+86</template>
					</el-input>
				</el-form-item>
				<el-form-item label="验证码:" prop="code">
					<el-input v-model="form.code">
						<template slot="append">
							<span class="code-button" v-show="codeStatus === 1" @click="handleSendCode">发送验证码</span>
							<span class="code-button" v-show="codeStatus === 2">{{ codeSeconds }}</span>
						</template>
					</el-input>
				</el-form-item>

			</el-form>
			<el-form v-show="loginType === '2'" ref="form2" label-width="80px" :model="form2" :rules="rules2"
					 :label-position="'top'">
				<el-form-item label="用户名:" prop="username">
					<el-input v-model="form2.username">
					</el-input>
				</el-form-item>
				<el-form-item label="密码:" prop="password">
					<el-input v-model="form2.password" type="password" show-password>
					</el-input>
				</el-form-item>

			</el-form>


			<div class="tip">
				<el-checkbox v-model="firstIsRegister"/>
				<span>
					未注册的手机号将自动注册。勾选即代表您同意并接受 <span class="clicked-span">服务协议</span> 与 <span
					class="clicked-span">隐私政策</span>
				</span>
			</div>

			<div class="submit">
				<el-button :loading="loading" type="primary" @click="handleSubmit">登录</el-button>
			</div>

			<div class="login-type">
				<div class="left">
					<span v-show="loginType === '1'" @click="changeLoginType('2')"> 用户名密码登录 </span>
					<span v-show="loginType === '2'" @click="changeLoginType('1')"> 手机验证码登录 </span></div>
				<div class="right">
					<span @click="toRegister()"> 注册 </span>
					<span @click="toForget()"> 忘记密码 </span>
				</div>
			</div>

			<div class="icp">
				<p>{{ $18n.company_name }}</p>
				<p>{{ $18n.icp }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import Header from "@/view/layout/Header";
import storage from "@/util/storage";

export default {
	name: "user-manage",
	components: {Header},
	data() {
		return {
			loading: false,
			loginType: '2', // 1手机验证码登录  2用户名密码登录
			form: {
				phone: '',
				code: ''
			},
			form2: {
				username: '',
				password: '',
			},
			rules: {
				phone: [
					{required: true, message: '请输入手机号', trigger: 'blur'},
					{pattern: /^1[3-9]\d{9}$/, message: '输入的手机号不正确', trigger: 'blur'}
				],
				code: [
					{required: true, message: '请输入验证码', trigger: 'blur'},
				]
			},
			rules2: {
				username: [
					{required: true, message: '请输入用户名', trigger: 'blur'},
				],
				password: [
					{required: true, message: '请输入密码', trigger: 'blur'},
				]
			},
			codeStatus: 1, // 1未发送 2发送中
			codeSeconds: 60,
			firstIsRegister: true
		}
	},
	methods: {
		handleSendCode() {

			setTimeout(() => {
				this.codeStatus = 2
				this.codeSeconds = 60
				this.handleSeconds()
			}, 1000)

			this.$refs.form.validateField(['phone'], (valid) => {
				if (!valid) {
					return
				}
				this.$http.post('/sys/getCaptcha', {phone: this.form.phone})
					.then(res => {
						if (res.code === 200) {
							this.codeStatus = 2
							this.codeSeconds = 60
							this.handleSeconds()
						} else {

						}
					})
			})

		},
		handleSeconds() {
			if (this.codeSeconds === 0) {
				this.codeStatus = 1
			} else {
				setTimeout(() => {
					this.codeSeconds--
					this.handleSeconds()
				}, 1000)
			}
		},
		handleSubmit() {
			if (this.loginType === '1') {// 手机验证码登录
				this.loading = true
				setTimeout(() => {
					this.loading = false
					if (this.$route.query.redirect) {
						this.$router.push(this.$route.query.redirect)
					} else {
						this.$router.push('/')
					}
				}, 1000)
			} else if (this.loginType === '2') { // 用户名密码登录

				this.$refs.form2.validate(valid => {
					const {username, password} = this.form2
					let data = {
						username,
						password,
						captcha: '123'
					}
					this.loading = true
					this.$http.post('/login?next=123', data).then(res => {
						if (res.success) {
							this.$message.success('登录成功')
							storage.set('token', res.data.token)
							if (this.$route.query.redirect) {
								this.$router.push(this.$route.query.redirect)
							} else {
								this.$router.push('/')
							}
							this.$store.dispatch('getUserInfo')

						}
					})
						.finally(() => {
							this.loading = false
						})
				})
			}

			// this.$refs.form.validate(valid => {
			// 	if (!valid) return
			// 	this.$router.push('/')
			// })
		},
		changeLoginType(type) {
			this.loginType = type
			if (type === '1') {
				this.$refs.form.resetFields()
			} else if (type === '2') {
				this.$refs.form2.resetFields()
			}
		},
		toRegister() {
			this.$router.push({name: 'register'})
		},
		toForget() {
			this.$router.push({name: 'forget'})
		},
	}
}
</script>

<style lang="less">
@import "../assets/css/user.less";
</style>
